// ------------------------------

export const apiKey = process.env.REACT_APP_API_KEY;

// ------------------------------

export const apiKeys = {
  firebaseConfig: {
    apiKey: apiKey,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  },
};

// ------------------------------

export const dbName = process.env.REACT_APP_DB_NAME;

// ------------------------------

export const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

// ------------------------------

export const stripeOAuthKey = process.env.REACT_APP_STRIPE_OATH_KEY;

// ------------------------------

export const sentryConfig = { dsn: process.env.REACT_APP_SENTRY_DNS };

// ------------------------------

export const zubuUrl = process.env.REACT_APP_ZUBU_URL;

// ------------------------------

export const appDomain = process.env.REACT_APP_DOMAIN;

// ------------------------------

export const appVersion = process.env.REACT_APP_VERSION;

// ------------------------------

export const zoomApiKey = process.env.REACT_APP_ZOOM_API_KEY;

// ------------------------------

export const zoomRedirectUrl = process.env.REACT_APP_ZOOM_REDIRECT_URL;

// ------------------------------

export const algoliaAppKey = process.env.REACT_APP_ALGOLIA_APP_KEY;
export const algoliaSearchKey = process.env.REACT_APP_ALGOLIA_SEARCH_KEY;

// ------------------------------

export const underMaintenance = false;


// ------------------------------

export const paroProvider = process.env.REACT_APP_PARO_PROVIDER;
export const thomasProvider = process.env.REACT_APP_THOMAS_PROVIDER;