import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import * as zoomActions from "../../../store/actions/zoom";
import * as courseActions from "../../../store/actions/courses";
import * as professorActions from "../../../store/actions/professor";
import * as certActions from "../../../store/actions/certificate";
import validate from "validate.js";
import Spinner from "../../../components/ui/spinner/Spinner";
import InfoModal from "../../../components/modals/infoModal/InfoModal";
import ConfirmModal from "../../../components/modals/confirmModal/ConfirmModal";
import Course from "../../../model/Course";
import MatStepper from "../../../components/ui/stepper/MatStepper";
import CourseEditForm from "./CourseEditForm";
import PricingScreen from "../pricing/PricingScreen";
import PreviewScreen from "../preview/PreviewScreen";
import ActivationScreen from "../activate/ActivationScreen";
import AlertSuccess from "../../../components/ui/snackbar/AlertSuccess";
import SimpleContainer from "../../../components/ui/container/Container";
import { constraints, hasFormErrors } from "../../../globals/Validations";
import { objectHasNoValues } from "../../../globals/Common";
import VideoChapterModal from "../../../components/modals/videoModal/VideoChapterModal";
import * as logger from "../../../globals/Logger";
import {
  LIVESTREAM,
  CLASSROOM,
  ONLINE_VIDEO,
  LECTURE_ONLY,
  HANDSON,
} from "../../../globals/Data";
import * as lodashCloneDeep from "lodash.clonedeep";

const CONTACT_POLICY_MISSING =
  "A cancellation policy is required before you can activate your course.  ";

class CourseEditScreen extends Component {
  state = {
    course: this.props.course,
    error: null,
    isInEdit: true,
    wait: false,
    hasErrors: false,
    activeStep: 0,
    activationChecked: false,
    validate: false,
    formErrors: {},
    touched: false,
    activationSuccessModalOpen: null,
    videoChapterModalOpen: null,
    videoChapterSelected: null,
    success: null,
    waitNoRender: null,
    waitMessage: "",
    warnPolicyOrContact: null,
    warnNoAddressOrDate: null,
    futurStep: 0,

    confirmExitPageTitle: null,
    confirmExitPage: null,
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      await this.update({ wait: true });
      const loadAsync = [];

      if (objectHasNoValues(this.props.professorList)) {
        loadAsync.push(this.props.fetchProfessor());
      }

      if (
        objectHasNoValues(this.props.zoomMeetings) &&
        this.props.zoomConnected
      ) {
        loadAsync.push(this.props.fetchZoomMeetings(this.props.course.id));
      }

      loadAsync.push(this.props.fetchCert());

      if (this.props.course && this.props.course.is_video) {
        loadAsync.push(this.props.fetchRecordedVideo(this.props.course.id));
      }

      await Promise.all(loadAsync);
      await this.update({ wait: false });
      window.scrollTo(0, 0);
    } catch (error) {
      logger.error(error);
      await this.update({
        wait: false,
        error: error.message,
      });
    }
  };

  // ------------------------------

  componentDidUpdate = async (prevProps) => {
    if (prevProps.course.id !== this.props.course.id) {
      console.log("Updating local course state")
      // refresh controlled draft state
      await this.update({
        course: this.props.course,
      });
    }

    if (prevProps.course.image !== this.props.course.image) {
      const copyCourse = lodashCloneDeep(this.state.course);

      copyCourse.image = this.props.course.image;
      await this.update({
        course: copyCourse,
      });
    }

    if (prevProps.course.video_chapters !== this.props.course.video_chapters) {
      const copyCourse = lodashCloneDeep(this.state.course);

      copyCourse.video_chapters = this.props.course.video_chapters;
      await this.update({
        course: copyCourse,
      });
    }

    if (prevProps.pathname !== this.props.pathname) {
      if (this.props.pathname === "/editcourse") {
        this.update({ activeStep: 0 });
      } else if (this.props.pathname === "/editcourse/pricing") {
        this.update({ activeStep: 1 });
      } else if (this.props.pathname === "/editcourse/preview") {
        this.update({ activeStep: 2 });
      } else if (this.props.pathname === "/editcourse/activation") {
        this.update({ activeStep: 3 });
      }
    }
  };

  // ------------------------------

  saveHandler = async (skipValidation) => {
    try {
      if (!skipValidation) {
        await this.validateForm();

        if (this.state.hasErrors) {
          await this.update({
            error: "Please verify course details.  Invalid fields.",
          });
          return false;
        }
      }

      if (this.state.touched) {
        await this.update({ waitNoRender: true });

        console.log(this.props.cId);
        console.log(this.state.course.id);
        if (this.props.cId === "new") {
          await this.props.addDraft(this.state.course);
        } else if (this.props.cId && this.state.course.id) {
          await this.props.updateDraft(this.state.course);
        }

        await this.update({
          waitNoRender: false,
          touched: false,
          success: "Course saved successfully",
        });
      }

      return true;
    } catch (err) {
      logger.error(err);
      this.update({ waitNoRender: false, error: err.message });
      return false;
    }
  };

  // ------------------------------

  validatePricingWithCapacity = () => {
    if (this.state.course.is_video) {
      return this.isVideoOptionValid();
    } else {
      return this.isLiveOptionValid();
    }
  };

  // ------------------------------

  isVideoOptionValid = () => {
    if (
      !this.hasSetting(this.state.course.pricingItems, ONLINE_VIDEO) ||
      this.hasSetting(this.state.course.pricingItems, CLASSROOM) ||
      this.hasSetting(this.state.course.pricingItems, LIVESTREAM) ||
      this.hasSetting(this.state.course.pricingItems, "Online") // legacy
    ) {
      const errorMessage = `Recorded video requires registrations options with a video setting only. Please review your registration options.`;
      this.update({ error: errorMessage });
      return false;
    }

    if (!this.hasMinOneNoExpiration(this.state.course.pricingItems)) {
      const errorMessage = `Recorded video requires a minimum of one registrations options with no expiration date. Please review your registration options.`;
      this.update({ error: errorMessage });
      return false;
    }

    return true;
  };

  // ------------------------------

  isLiveOptionValid = () => {
    return (
      this.validateHasPricingOptionNoCapacity() &&
      this.validateHasCapacityNoPricingOption()
    );
  };

  // ------------------------------

  validateHasCapacityNoPricingOption = () => {
    if (this.hasSetting(this.state.course.pricingItems, ONLINE_VIDEO)) {
      const errorMessage = `Live Speaker option requires a registration option with Classroom and/or Livestream setting. Please review your registration options.`;
      this.update({ error: errorMessage });
      return false;
    }

    if (
      this.hasClassroomLectureSeats(this.state.course) &&
      !this.hasSetting(this.state.course.pricingItems, CLASSROOM) &&
      (!this.hasComponent(this.state.course.pricingItems, LECTURE_ONLY) ||
        !this.hasComponent(this.state.course.pricingItems, HANDSON))
    ) {
      const errorMessage = `Classroom lecture capacity was set to  ${this.state.course.seats_classroom === -2
        ? "Unlimited"
        : this.state.course.seats_classroom
        }  but no registration option was entered.`;
      this.update({ error: errorMessage });
      return false;
    }

    if (
      this.hasClassroomHandsonSeats(this.state.course) &&
      (!this.hasSetting(this.state.course.pricingItems, CLASSROOM) ||
        !this.hasComponent(this.state.course.pricingItems, HANDSON))
    ) {
      const errorMessage = `Classroom hands-on capacity was set to  ${this.state.course.seats_handson === -2
        ? "Unlimited"
        : this.state.course.seats_handson
        }  but no registration option was entered.`;
      this.update({ error: errorMessage });
      return false;
    }

    if (
      this.hasLiveStreamOrOnlineVideoSeats(this.state.course) &&
      !this.hasSetting(this.state.course.pricingItems, LIVESTREAM)
    ) {
      const errorMessage = `Livestream capacity was set to  ${this.state.course.seats_online === -2
        ? "Unlimited"
        : this.state.course.seats_online
        }  but no registration option was entered.`;
      this.update({ error: errorMessage });
      return false;
    }

    return true;
  };

  // ------------------------------

  validateHasPricingOptionNoCapacity = () => {
    if (
      this.hasSetting(this.state.course.pricingItems, CLASSROOM) &&
      this.hasComponent(this.state.course.pricingItems, LECTURE_ONLY) &&
      !this.hasClassroomLectureSeats(this.state.course)
    ) {
      const errorMessage = `Classroom lecture capacity was set to "N/A" but you added a registration option for a Classroom lecture course.  Please review your registration options`;
      this.update({ error: errorMessage });
      return false;
    }

    if (
      this.hasSetting(this.state.course.pricingItems, CLASSROOM) &&
      this.hasComponent(this.state.course.pricingItems, HANDSON) &&
      !this.hasClassroomHandsonSeats(this.state.course)
    ) {
      const errorMessage = `Classroom hands-on capacity was set to "N/A" but you added a registration option for a Classroom hands-on course.  Please review your registration options`;
      this.update({ error: errorMessage });
      return false;
    }

    if (
      this.hasSetting(this.state.course.pricingItems, LIVESTREAM) &&
      !this.hasLiveStreamOrOnlineVideoSeats(this.state.course)
    ) {
      const errorMessage = `Livestream capacity was set to "N/A" but you added a registration option for a Livestream course.  Please review your registration options`;
      this.update({ error: errorMessage });
      return false;
    }

    return true;
  };

  // -------------------------------------------------------------

  validateAddressWithClassroom = () => {
    const arr = [];

    if (
      this.state.course.seats_classroom > 0 ||
      this.state.course.seats_handson > 0
    ) {
      if (!this.state.course.address.join("")) {
        arr.push("Course Address");
      }
    }

    if (!this.state.course.is_video) {
      if (!this.state.course.start_date || !this.state.course.start_date[0]) {
        arr.push("Course Dates");
      }
    }

    if (arr.length > 0) {
      return (
        "Missing values for " +
        arr.join(", ") +
        ". If omitted, TBD (To be determined) will be displayed on your course listing.  Do you wish to continue?"
      );
    }

    return "";
  };

  // ------------------------------

  hasMinOneNoExpiration = (pricingItems) => {
    for (const pi of pricingItems) {
      if (!pi.expiration_date) {
        return true;
      }
    }

    return false;
  };

  // ------------------------------

  hasSetting = (pricingItems, setting) => {
    for (const pi of pricingItems) {
      if (pi.casting === setting) {
        return true;
      }
    }

    return false;
  };

  // ------------------------------

  hasClassroomLectureSeats = (course) => {
    return Number(course.seats_classroom) !== -1;
  };

  // ------------------------------

  hasClassroomHandsonSeats = (course) => {
    return Number(course.seats_handson) !== -1;
  };

  // ------------------------------

  hasLiveStreamOrOnlineVideoSeats = (course) => {
    return Number(course.seats_online) !== -1;
  };

  // ------------------------------

  hasComponent = (pricingItems, component) => {
    for (const pi of pricingItems) {
      if (pi.component === component) {
        return true;
      }
    }

    return false;
  };

  // ------------------------------

  quickSaveProfessor = async (professorName) => {
    try {
      this.update({ wait: true });
      await this.props.addProfessor(professorName, this.state.course);
      this.update({ wait: false });
    } catch (err) {
      logger.error(err);
      this.update({ wait: false, error: err.message });
    }
  };

  // ------------------------------

  activateCourseHandler = async () => {
    try {
      if (this.state.course.id) {
        if (this.missingContactOrPolicy()) {
          await this.update({
            warnPolicyOrContact: CONTACT_POLICY_MISSING,
          });
          return false;
        }

        if (this.isCourseDuplicate()) {
          await this.update({
            error: "Duplicate course.  Please verify your course details.",
          });
          return false;
        }
        await this.update({
          waitNoRender: true,
          waitMessage: "Activating Course",
        });
        await this.props.activateCourse(this.state.course);
        await this.update({
          waitNoRender: false,
          waitMessage: "",
          activationSuccessModalOpen: true,
        });
      }
    } catch (err) {
      logger.error(err);
      this.update({ waitNoRender: false, waitMessage: "", error: err.message });
    }
  };

  // ------------------------------

  updateStepState = async (activeStep) => {
    await this.setState({
      ...this.state,
      activeStep: activeStep,
      activationChecked: false,
    });
  };

  // ------------------------------

  isCourseDuplicate = () => {
    for (const course of this.props.activeCourses) {
      if (
        course.title.toUpperCase() === this.state.course.title.toUpperCase() &&
        course.address.join("") === this.state.course.address.join("")
      ) {
        return true;
      }
    }

    return false;
  };

  // ------------------------------

  missingContactOrPolicy = () => {
    return (
      !this.state.course.cancel_policy ||
      this.state.course.cancel_policy.length < 5
    );
  };

  // ------------------------------

  stepHandler = async (goingForward) => {
    const basePath = "/editcourse";

    let activeStep = this.state.activeStep;
    if (goingForward) {
      activeStep = activeStep + 1;
    } else {
      activeStep = activeStep - 1;
    }

    switch (activeStep) {
      case 0:
        this.updateStepState(activeStep);
        this.props.history.push(basePath);
        break;
      case 1:
        if (goingForward) {
          const ok = await this.saveHandler();
          const hasWarnMsg = this.validateAddressWithClassroom();

          if (ok && !hasWarnMsg) {
            this.updateStepState(activeStep);
            this.props.history.push(basePath + "/pricing");
          } else if (hasWarnMsg) {
            this.update({
              warnNoAddressOrDate: hasWarnMsg,
              futurStep: activeStep,
            });
          }
        } else {
          this.updateStepState(activeStep);
          this.props.history.push(basePath + "/pricing");
        }
        break;
      case 2:
        if (goingForward) {
          const ok = await this.saveHandler();
          const validCapacity = this.validatePricingWithCapacity();

          if (ok && validCapacity) {
            this.updateStepState(activeStep);
            this.props.history.push(basePath + "/preview");
          }
        } else {
          this.updateStepState(activeStep);
          this.props.history.push(basePath + "/preview");
        }
        break;
      case 3:
        this.updateStepState(activeStep);
        this.props.history.push(basePath + "/activation");
        break;
      case 4:
        await this.activateCourseHandler();
        break;
      default:
        break;
    }

    window.scrollTo(0, 0);
  };

  // ------------------------------

  stepperExitHandler = () => {
    this.props.history.push("/courses");
  };

  // ------------------------------

  pricingItemsUpdateHandler = (pricingItems) => {
    const courseCopy = { ...this.state.course };
    courseCopy.pricingItems = [...pricingItems];
    this.setState({ ...this.state, course: courseCopy, touched: true });
  };

  // ------------------------------

  updateMultiPayOption = (multiPayOption) => {
    const courseCopy = { ...this.state.course };
    courseCopy.multi_pay_option = { ...multiPayOption };
    this.setState({ ...this.state, course: courseCopy, touched: true });
  };

  // ------------------------------

  validateForm = async () => {
    const e0 = {};
    const e1 = this.validateField(e0, "category", "required");
    const e1_0 = this.validateField(e1, "accreditation", "required");
    const e2 = this.validateField(e1_0, "language", "required");
    const e3 = this.validateField(e2, "title", "plainRequired");
    const e4 = this.validateField(e3, "description", "plainRequired");
    const e5 = this.validateField(e4, "objectives", "plainRequired");
    const e7 = this.validateField(e5, "seats_classroom", "required");
    const e7_0 = this.validateField(e7, "seats_online", "required");

    //if (this.state.course.is_video) {
    await this.update({ formErrors: e7_0 });
    await this.hasErrorsHandler(e7_0);
    //} else {
    //const e8 = this.validateField(e7_0, "start_date", "required");
    // await this.update({ formErrors: e8 });
    // await this.hasErrorsHandler(e8);
    // }
  };

  // ------------------------------

  validateHandler = async (field, validatorKey) => {
    if (field === null) {
      await this.hasErrorsHandler(this.state.formErrors);
    } else {
      const errors = this.validateField(
        this.state.formErrors,
        field,
        validatorKey
      );
      await this.update({ formErrors: errors }); // await is crutial here
      await this.hasErrorsHandler(errors);
    }
  };

  // ------------------------------

  clearError = async (field) => {
    const copyFormErrors = { ...this.state.formErros };
    delete copyFormErrors[field];
    await this.update({ formErrors: copyFormErrors });
  };

  // ------------------------------

  validateField = (paramFormErrors, field, validatorKey) => {
    let validateObj = {};
    validateObj[validatorKey] = this.state.course[field];
    const validationResponse = validate(validateObj, constraints);
    const updateFormErrors = { ...paramFormErrors };

    if (validationResponse[validatorKey]) {
      const firstError = validationResponse[validatorKey][0];
      updateFormErrors[field] = firstError;
    } else {
      delete updateFormErrors[field];
    }

    return updateFormErrors;
  };

  // ------------------------------

  hasErrorsHandler = async (formErrors) => {
    await this.update({ hasErrors: hasFormErrors(formErrors, {}) });
  };

  // ------------------------------

  updateCancelConfirmHandler = async (answer) => {
    if (answer === "ok") {
      await this.update({
        warnPolicyOrContact: null,
      });

      this.props.history.push("/settings/profile");
    } else {
      await this.update({
        warnPolicyOrContact: null,
      });
    }
  };

  // ------------------------------

  confirmHandler = async (answer) => {
    if (answer === "ok") {
      await this.updateStepState(this.state.futurStep);
      await this.update({ warnNoAddressOrDate: null, futurStep: 0 });
      await this.props.history.push("/editcourse/pricing");
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 1);
    } else {
      await this.update({ warnNoAddressOrDate: null, futurStep: 0 });
    }
  };

  // ------------------------------

  confirmExitHandler = async (answer) => {
    if (answer === "ok") {
      const navPage = this.state.confirmExitPage;
      if (navPage === "/survey" || navPage === "/certificate") {
        await this.props.history.push(this.state.confirmExitPage);
      }
    } else {
      await this.update({ confirmExitPage: null, confirmExitPageTitle: null });
    }
  };

  // ------------------------------

  // directly deletes from storage
  removeImageHandler = async (imageBlob) => {
    try {
      const copyCourse = lodashCloneDeep(this.state.course);

      await this.updateImageState("");

      if (!imageBlob.startsWith("blob:") && copyCourse.id) {
        await this.update({ wait: true });
        await this.props.uploadDeleteCourseImage(imageBlob, copyCourse);
        await this.update({
          wait: false,
          success: "Photo removed",
        });
      }
    } catch (err) {
      logger.error(err);
      this.update({
        wait: false,
        error: err.message,
      });
    }
  };

  // ------------------------------

  // save in general save
  updateImageState = async (imageBlob) => {
    try {
      const copyCourse = lodashCloneDeep(this.state.course);
      copyCourse.image = imageBlob;
      await this.update({
        course: copyCourse,
        touched: true,
      });
    } catch (err) {
      logger.error(err);
      this.update({
        wait: false,
        error: err.message,
      });
    }
  };

  // ------------------------------

  onNewEditVideoChapter = async (videoChapter) => {
    await this.update({
      videoChapterModalOpen: true,
      videoChapterSelected: videoChapter
    });
  }

  // ------------------------------

  addEditVideoChapter = async (videoChapter) => {
    try {
      const copyCourse = lodashCloneDeep(this.state.course);

      if (!copyCourse.video_chapters) {
        copyCourse.video_chapters = [];
      }

      const index = copyCourse.video_chapters.findIndex(vc => vc.id == videoChapter.id);

      if (index > -1) {
        copyCourse.video_chapters[index] = videoChapter
      }
      else {
        copyCourse.video_chapters.push(videoChapter);
      }

      await this.update({
        course: copyCourse,
        touched: true,
        videoChapterModalOpen: false,
      });
    } catch (err) {
      logger.error(err);
      this.update({
        wait: false,
        error: err.message,
        videoChapterModalOpen: false
      });
    }
  }

  // ------------------------------

  onUpdateVideoChapterOrder = (chapters) => {
    const copyCourse = lodashCloneDeep(this.state.course);
    copyCourse.video_chapters = chapters;
    this.update({ course: copyCourse, touched: true });
  }

  // ------------------------------

  render() {
    if (!this.props.cId) {
      return <Redirect to="/courses" />;
    }

    if (this.state.wait) {
      return <Spinner />;
    }

    const stepper = (
      <MatStepper
        steps={[
          "Enter Course Details",
          "Enter Price / Credits",
          "Preview",
          "Acivate on ZubU",
        ]}
        touched={this.state.touched}
        activeStep={this.state.activeStep}
        onSave={this.saveHandler.bind(this, false)}
        onStep={this.stepHandler.bind(this)}
        onReset={async () => {
          await this.update({ activeStep: 0 });
          this.stepHandler(false);
        }}
        onExit={this.stepperExitHandler}
        blocked={
          this.state.hasErrors ||
          (this.state.activationChecked === false &&
            this.state.activeStep === 3) ||
          ((this.state.course.pricingItems === null ||
            this.state.course.pricingItems.length < 1) &&
            this.state.activeStep === 1)
        }
      />
    );

    return (
      <div style={{ background: "white" }}>
        {this.state.waitNoRender && (
          <Spinner message={this.state.waitMessage} />
        )}

        {this.state.success !== null && (
          <AlertSuccess
            message={this.state.success}
            open={this.state.success !== null}
            onClose={() => {
              this.update({ success: null });
            }}
          />
        )}

        {stepper}

        <SimpleContainer disableGutters={false}>
          {this.state.confirmExitPage !== null && (
            <ConfirmModal
              isInfoModal
              title="Confirmation Required"
              message={
                "You are about to navigate away from this page.  Any unsaved changes will be lost.  Do you wish to exit?"
              }
              open={this.state.confirmExitPage !== null}
              onClose={this.confirmExitHandler}
              cancelButtonText="Cancel"
              okButtonText={"Go to " + this.state.confirmExitPageTitle}
            />
          )}

          {this.state.warnNoAddressOrDate !== null && (
            <ConfirmModal
              isWarnModal
              title="Missing Information"
              message={this.state.warnNoAddressOrDate}
              open={this.state.warnNoAddressOrDate !== null}
              onClose={this.confirmHandler}
              cancelButtonText="Cancel"
              okButtonText="Continue"
            />
          )}

          {this.state.error !== null && (
            <InfoModal
              isErrorModal={true}
              title="An error has occured"
              message={this.state.error}
              open={this.state.error !== null}
              onClose={() => {
                this.update({ error: null });
              }}
            />
          )}

          {this.state.warnPolicyOrContact !== null && (
            <ConfirmModal
              title="Update your cancellation policy"
              message={
                this.state.warnPolicyOrContact +
                " Do you wish to update your cancellation policy now?"
              }
              open={this.state.warnPolicyOrContact !== null}
              onClose={this.updateCancelConfirmHandler}
              cancelButtonText="No"
              okButtonText="Yes"
            />
          )}

          {this.state.activationSuccessModalOpen !== null && (
            <InfoModal
              title="Course Activation Success"
              message="Congratulations you have completed your course activation.  It will be available on the ZubU mobile app pending our review."
              open={true}
              onClose={() => {
                this.update({
                  activationSuccessModalOpen: null,
                });
                this.props.history.push("/courses");
              }}
            />
          )}

          {this.state.videoChapterModalOpen !== null && (
            <VideoChapterModal
              selected={this.state.videoChapterSelected}
              open={this.state.videoChapterModalOpen}
              onClose={(videoChapter) => {
                if (videoChapter) {
                  this.addEditVideoChapter(videoChapter);
                }
                else {
                  this.update({
                    videoChapterModalOpen: null,
                  });
                }
              }}
            />
          )}

          <Route
            path="/editcourse"
            exact
            render={() => (
              <CourseEditForm
                profile={this.props.profile}
                certificate={this.props.certificate}
                zoomMeetings={this.props.zoomMeetings}
                zoomConnected={this.props.zoomConnected}
                course={this.state.course}
                surveyList={this.props.surveyList}
                professorList={this.props.professorList}
                formErrors={this.state.formErrors}
                hasErrors={this.state.hasErrors}
                isInEdit={this.state.isInEdit}
                validate={(field, validationkey) => {
                  this.validateHandler(field, validationkey);
                }}
                update={async (course) => {
                  await this.update({ course: course, touched: true });
                }}
                updateAndValidate={async (course) => {
                  await this.update({ course: course, touched: true });

                  await this.validateForm();
                }}
                onAddProfessor={this.quickSaveProfessor}
                onAddSurvey={() => {
                  this.update({
                    confirmExitPage: "/survey",
                    confirmExitPageTitle: "Survey",
                  });
                }}
                onAddCertificate={() => {
                  this.update({
                    confirmExitPage: "/certificate",
                    confirmExitPageTitle: "Certificate",
                  });
                }}
                saveImage={async (blob) => {
                  if (blob && blob.startsWith("blob:")) {
                    await this.updateImageState(blob);
                  }
                }}
                onDeleteImage={async (img) => {
                  await this.removeImageHandler(img);
                }}
                onNewEditVideoChapter={this.onNewEditVideoChapter}
                onUpdateVideoChapterOrder={this.onUpdateVideoChapterOrder}
              />
            )}
          />

          <Route
            exact
            path="/editcourse/pricing"
            render={() => (
              <PricingScreen
                multiPayOption={this.state.course.multi_pay_option}
                currency={this.props.currency}
                is_video={this.state.course.is_video}
                is_specialty={this.state.course.is_specialty}
                courseStartDate={this.state.course.start_date[0]}
                pricingItems={this.state.course.pricingItems}
                updatePricingItems={this.pricingItemsUpdateHandler.bind(this)}
                updateMultiPayOption={this.updateMultiPayOption.bind(this)}
              />
            )}
          />

          <Route
            exact
            path="/editcourse/preview"
            render={() => <PreviewScreen course={this.state.course} />}
          />

          <Route
            exact
            path="/editcourse/activation"
            render={() => (
              <ActivationScreen
                contextTitle="Course activation on ZubU"
                title={this.state.course.title}
                activationConfirmation={this.state.activationChecked}
                updateActivationCheck={(confirmed) => {
                  this.setState({
                    ...this.state,
                    activationChecked: confirmed,
                  });
                }}
              />
            )}
          />

        </SimpleContainer>

        {stepper}

        <div style={{ height: "70px" }}>&nbsp;</div>
      </div>
    );
  }
}

// ------------------------------

const mapStateToProps = (state, props) => {
  const cId = state.courses.courseId;
  const allCourses = [...state.courses.courses, ...state.courses.drafts];
  const editCourse = allCourses.find((c) => c.id === cId);
  const courseNewOrEdit = editCourse ? editCourse : new Course();
  const profile = state.profile.profile;

  courseNewOrEdit.email = profile.contact_email;
  courseNewOrEdit.cancel_policy = profile.cancel_policy;


  return {
    certificate: state.certificate.certificate,
    zoomMeetings: state.zoom.zoom_meetings,
    zoomConnected: state.zoom.connected,
    course: courseNewOrEdit,
    cId: cId,
    professorList: state.professor.professor,
    surveyList: state.survey.survey,
    activeCourses: state.courses.courses,
    currency: profile.currency,
    pathname: props.location.pathname,
    profile: profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addDraft: (course) => dispatch(courseActions.addDraft(course)),
    activateCourse: (course) => dispatch(courseActions.activateCourse(course)),
    updateDraft: (course) => dispatch(courseActions.updateDraft(course)),
    addProfessor: (prof, course) =>
      dispatch(professorActions.addProfessor(prof, course)),
    fetchProfessor: () => dispatch(professorActions.fetchProfessor()),
    fetchZoomMeetings: () => dispatch(zoomActions.fetchZoomMeetings()),
    fetchCert: () => dispatch(certActions.fetchCert()),
    uploadDeleteCourseImage: (blob, course) =>
      dispatch(courseActions.uploadDeleteCourseImage(blob, course)),
    fetchRecordedVideo: (courseId) => dispatch(courseActions.fetchRecordedVideo(courseId, false)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseEditScreen);
