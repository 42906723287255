import MobileRegistration from "../../model/MobileRegistration";
import {
  SET_REGISTRATION,
  SET_REGISTRATION_ARCHIVED,
  REMOVE_REGISTRATION,
  UPDATE_RATED_REGISTRATION,
  UPDATE_ALERT_SETTING,
  UPDATE_ARCHIVED_REGISTRATION,
  UPDATE_REGISTRATION_CONTENT,
  SET_REGISTRANTS,
  UPDATE_SINGLE_REGISTRATION,
} from "../actions/registration";

const initialState = {
  toBeRatedCount: 0,
  registration: [],
  registrationArchived: [],
  selected_instruction: "",
  selected_handout: [],
  hasMore: true,
  hasMoreArchived: true,
  staffRegistered: [],
};

const registrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SINGLE_REGISTRATION: {
      let updatedAllCourseList = [...state.registration];

      const regAllCourseIndex = updatedAllCourseList.findIndex(
        (reg) => reg.id === action.id
      );

      if (regAllCourseIndex > -1) {
        updatedAllCourseList[regAllCourseIndex] = action.registration;

        return {
          ...state,
          registration: updatedAllCourseList,
        };
      } else {
        let updatedArchivedRegList = [...state.registrationArchived];

        const regArchiveCourseIndex = updatedArchivedRegList.findIndex(
          (reg) => reg.id === action.registration.id
        );

        if (regArchiveCourseIndex > -1) {
          updatedArchivedRegList[regArchiveCourseIndex] = action.registration;

          return {
            ...state,
            registrationArchived: updatedArchivedRegList,
          };
        }
      }
    }
    case SET_REGISTRANTS:
      return {
        ...state,
        staffRegistered: action.staffRegistered,
      };
    case SET_REGISTRATION:
      return {
        ...state,
        registration: action.registration,
        toBeRatedCount: action.toBeRatedCount,
        hasMore: action.hasMore,
      };
    case SET_REGISTRATION_ARCHIVED:
      return {
        ...state,
        registrationArchived: action.registrationArchived,
        hasMoreArchived: action.hasMoreArchived,
      };
    case UPDATE_ARCHIVED_REGISTRATION:
      let updatedAllCourseList = [...state.registration];
      let updatedArchivedRegList = [...state.registrationArchived];
      let copyArchiveReg = null;

      const regAllCourseIndex = updatedAllCourseList.findIndex(
        (reg) => reg.id === action.registration.id
      );

      let regArchiveCourseIndex = -1;

      if (regAllCourseIndex > -1) {
        copyArchiveReg = updatedAllCourseList[regAllCourseIndex];
      } else {
        regArchiveCourseIndex = updatedArchivedRegList.findIndex(
          (reg) => reg.id === action.registration.id
        );

        copyArchiveReg = updatedArchivedRegList[regArchiveCourseIndex];
      }

      const updatedArchiveReg = new MobileRegistration(
        copyArchiveReg.id,
        copyArchiveReg.image,
        copyArchiveReg.provider_id,
        copyArchiveReg.order_ref_number,
        copyArchiveReg.course_id,
        copyArchiveReg.course_title,
        copyArchiveReg.course_date,
        copyArchiveReg.casting,
        copyArchiveReg.audience,
        copyArchiveReg.component,
        copyArchiveReg.registered_date,
        copyArchiveReg.unregistered_date,
        copyArchiveReg.rated_date,
        copyArchiveReg.provider_instruction,
        copyArchiveReg.alert_identifier,
        copyArchiveReg.alert_setting,
        action.registration.archived,
        action.registration.archived_date,
        copyArchiveReg.zoom_id,
        copyArchiveReg.zoom_join_url,
        copyArchiveReg.survey_token,
        copyArchiveReg.certificate_uri,
        copyArchiveReg.certificate_uri_fr,
        copyArchiveReg.certificate_staff_uri
      );

      if (action.registration.archived) {
        updatedAllCourseList.splice(regAllCourseIndex, 1);
        updatedArchivedRegList.push(updatedArchiveReg);
      } else {
        updatedAllCourseList.push(updatedArchiveReg);
        updatedArchivedRegList.splice(regArchiveCourseIndex, 1);
      }

      return {
        ...state,
        registration: updatedAllCourseList,
        registrationArchived: updatedArchivedRegList,
        animate: null,
      };
    case REMOVE_REGISTRATION: {
      const copyState = [...state.registration];

      const updatedRegistration = copyState.filter((registration) => {
        return registration.id !== action.registration.id;
      });

      const copyStateArchived = [...state.registrationArchived];

      const updatedRegArchived = copyStateArchived.filter((registration) => {
        return registration.id !== action.registration.id;
      });

      return {
        ...state,
        registration: updatedRegistration,
        registrationArchived: updatedRegArchived,
      };
    }
    case UPDATE_RATED_REGISTRATION: {
      const updatedRatedReg = [...state.registration];

      const regIndex = updatedRatedReg.findIndex(
        (reg) => reg.id === action.registration.id
      );

      const copyReg = updatedRatedReg[regIndex];

      const updatedReg = new MobileRegistration(
        copyReg.id,
        copyReg.image,
        copyReg.provider_id,
        copyReg.order_ref_number,
        copyReg.course_id,
        copyReg.course_title,
        copyReg.course_date,
        copyReg.casting,
        copyReg.audience,
        copyReg.component,
        copyReg.registered_date,
        copyReg.unregistered_date,
        new Date(),
        copyReg.provider_instruction,
        copyReg.alert_identifier,
        copyReg.alert_setting,
        copyReg.archived,
        copyReg.archived_date,
        copyReg.zoom_id,
        copyReg.zoom_join_url,
        copyReg.survey_token,
        copyReg.certificate_uri,
        copyReg.certificate_uri_fr,
        copyReg.certificate_staff_uri
      );

      updatedRatedReg[regIndex] = updatedReg;

      let toBeRatedCount = 0;
      updatedRatedReg.map((r) => {
        toBeRatedCount += r.requiresRating() ? 1 : 0;
      });

      return {
        ...state,
        toBeRatedCount: toBeRatedCount,
        registration: updatedRatedReg,
      };
    }
    case UPDATE_ALERT_SETTING:
      const updatedAlertRegList = [...state.registration];

      const regAlertIndex = updatedAlertRegList.findIndex(
        (reg) => reg.id === action.registration.id
      );

      const copyAlertReg = updatedAlertRegList[regAlertIndex];

      const updatedAlertReg = new MobileRegistration(
        copyAlertReg.id,
        copyAlertReg.image,
        copyAlertReg.provider_id,
        copyAlertReg.order_ref_number,
        copyAlertReg.course_id,
        copyAlertReg.course_title,
        copyAlertReg.course_date,
        copyAlertReg.casting,
        copyAlertReg.audience,
        copyAlertReg.component,
        copyAlertReg.registered_date,
        copyAlertReg.unregistered_date,
        copyAlertReg.rated_date,
        copyAlertReg.provider_instruction,
        action.registration.alert_identifier,
        action.registration.alert_setting,
        copyAlertReg.archived,
        copyAlertReg.archived_date,
        copyAlertReg.zoom_id,
        copyAlertReg.zoom_join_url,
        copyAlertReg.survey_token,
        copyAlertReg.certificate_uri,
        copyAlertReg.certificate_uri_fr,
        copyAlertReg.certificate_staff_uri
      );

      updatedAlertRegList[regAlertIndex] = updatedAlertReg;

      return {
        ...state,
        registration: updatedAlertRegList,
      };
    case UPDATE_REGISTRATION_CONTENT:
      return {
        ...state,
        selected_instruction: action.selected_instruction,
        selected_handout: action.selected_handout,
      };
    case "LOGOUT":
      return initialState;
    default:
      return state;
  }
};

export default registrationReducer;
