import {
  SPECIALIST,
  OCCUPATION_DENTIST_ONLY,
  OCCUPATION_HYGIENIST,
  OCCUPATION_OTHER,
} from "../globals/Data";
import { valueInCollection } from "../globals/Common";

class MobileUserProfile {
  constructor(
    id,
    first,
    last,
    gender,
    occupation,
    email,
    birthDate,
    graduationDate,
    licencingBoard,
    licenseNumber,
    phone,
    streetNumber,
    streetName,
    suite,
    country,
    stateProvince,
    city,
    zipPostal,
    geoLat,
    geoLng,
    geoHash,
    zubuName,
    zubuLocation
  ) {
    this.id = id;
    this.first = first;
    this.last = last;
    this.gender = gender;
    this.occupation = occupation;
    this.email = email;
    this.birthDate = birthDate;
    this.graduationDate = graduationDate;
    this.licencingBoard = licencingBoard;
    this.licenseNumber = licenseNumber;
    this.phone = phone;
    this.streetNumber = streetNumber;
    this.streetName = streetName;
    this.suite = suite;
    this.country = country;
    this.stateProvince = stateProvince;
    this.city = city;
    this.zipPostal = zipPostal;
    this.geoLat = geoLat;
    this.geoLng = geoLng;
    this.geoHash = geoHash;
    this.zubuName = zubuName;
    this.zubuLocation = zubuLocation;
  }

  prefix = () => {
    return this.isDentist() || this.isSpecialist() ? "Dr. " : "";
  };

  isSpecialist = () => {
    return valueInCollection(SPECIALIST, this.occupation);
  };

  isDentist = () => {
    return valueInCollection(OCCUPATION_DENTIST_ONLY, this.occupation);
  };

  isHygienist = () => {
    return valueInCollection(OCCUPATION_HYGIENIST, this.occupation);
  };

  isOther = () => {
    return valueInCollection(OCCUPATION_OTHER, this.occupation);
  };

  occupationMatch = (audience) => {
    if (this.isSpecialist()) {
      return (
        audience.indexOf("Specialist") > -1 || audience.indexOf("Dentist") > -1
      );
    }

    if (this.isDentist()) {
      return audience.indexOf("Dentist") > -1;
    }

    if (this.isHygienist()) {
      return audience.indexOf("Hygienist") > -1;
    }

    if (this.isOther()) {
      return audience.indexOf("Others") > -1;
    }

    return false;
  };

  isODQ = () => {
    return this.licencingBoard === "Yes";
  };

  completedProfile = () => {
    const fullProfile =
      this.id &&
      this.first &&
      this.last &&
      // this.gender &&
      this.occupation &&
      this.email &&
      // this.birthDate &&
      // this.graduationDate &&
      this.licencingBoard &&
      this.licenseNumber;
    // this.phone &&
    // this.city;

    return fullProfile;
  };

  resolveChatName = () => {
    if (this.zubuName) {
      return this.zubuName;
    } else {
      return this.first;
    }
  };
}

export default MobileUserProfile;
