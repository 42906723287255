import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Tab from "@material-ui/core/Tab";
import styles from "./MyRegistrationScreen.module.css";
import Zoom from "@material-ui/core/Zoom";
import MatTabs from "../../../components/ui/tabs/MatTabs";
import * as ceActions from "../../../store/actions/ces";
import * as registrationActions from "../../../store/actions/registration";
import * as surveyActions from "../../../store/actions/survey";
import * as rateActions from "../../../store/actions/rate";
import * as providerActions from "../../../store/actions/provider";
import * as courseActions from "../../../store/actions/courses";
import * as settingActions from "../../../store/actions/setting";
import * as profileActions from "../../../store/actions/profile";
import * as inboxActions from "../../../store/actions/inbox";
import RegistrationItem from "../../../components/items/registrationItem/RegistrationItem";
import InfoModal from "../../../components/modals/infoModal/InfoModal";
import Spinner from "../../../components/ui/spinner/Spinner";
import CourseViewModal from "../../../components/modals/courseViewModal/CourseViewModal";
import Empty from "../../../components/ui/empty/Empty";
import regImg from "../../../assets/images/inApp/registration.png";
import archivedImg from "../../../assets/images/inApp/archived.png";
import AlertSuccess from "../../../components/ui/snackbar/AlertSuccess";
import RegistrationContentModal from "../../../components/modals/registrationContentModal/RegistrationContentModal";
import StaffRegisteredModal from "../../../components/modals/staffRegisteredModal/StaffRegisteredModal";
import ShareCourseModal from "../../../components/modals/shareCourseModal/ShareCourseModal";
import HandoutModal from "../../../components/modals/handoutModal/HandoutModal";
import DocumentListModal from "../../../components/modals/documentListModal/DocumentListModal";
import SurveyPreviewModal from "../../../components/modals/surveyModal/SurveyPreviewModal";

import CeSummary from "../../../components/ui/ceSummary/CeSummary";

import {
  objectHasNoValues,
  findCurrency,
  deepEqual,
} from "../../../globals/Common";
import InboxChatModal from "../../../components/modals/inboxChatModal/InboxChatModal";
import RatingModal from "../../../components/modals/ratingModal/RatingModal";
import * as logger from "../../../globals/Logger";
import SearchIcon from "@material-ui/icons/Search";
import LoadMore from "../../../components/ui/pagination/LoadMore";


const CURRENT_YEAR = new Date().getFullYear();

class MyRegistrationsScreen extends Component {
  state = {
    wait: false,
    waitMessage: "",
    viewCourse: null,
    // -----
    message: null,
    messageTitle: null,
    errorModal: false,
    alertSuccess: null,
    severity: null,
    openShareCourseModal: null,
    activeTab: 0,
    activeCourses: this.props.registeredCourses
      ? this.props.registeredCourses
      : [],
    showCourseModal: false,
    showHandoutModal: false,
    showInstructionModal: false,
    showChatModal: false,
    showShareCourseModal: false,
    showStaffRegisteredModal: false,
    activeConversation: null,
    showRatingModal: false,
    showDocumentListModal: false,
    showSurveyPreviewModal: false,
    documentListModalError: null,
    selectedRegistrationCard: null,
    isLoadingMore: false,
    page: 1,
    archivedPage: 1,
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      window.scrollTo(0, 0);
      this.update({ wait: true });
      const loadAsync = [];

      if (objectHasNoValues(this.props.mobileSetting)) {
        await this.props.fetchMobileSetting();
      }

      loadAsync.push(this.props.fetchRegistration(1));
      loadAsync.push(this.props.fetchArchivedRegistration(1));

      if (objectHasNoValues(this.props.rates)) {
        loadAsync.push(this.props.fetchRates());
      }
      if (objectHasNoValues(this.props.profile)) {
        loadAsync.push(this.props.fetchUserProfile());
      }
      if (objectHasNoValues(this.props.provider)) {
        loadAsync.push(this.props.fetchProviders());
      }

      await Promise.all(loadAsync);
      await this.props.fetchCeByYear(CURRENT_YEAR, CURRENT_YEAR);
      await this.update({ wait: false });
      window.scrollTo(0, 0);
    } catch (error) {
      logger.error(error);
      await this.update({
        wait: false,
        errorModal: true,
        message: error.message,
      });
    }
  };

  // ------------------------------

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.registeredCourses.length !==
      this.props.registeredCourses.length ||
      prevProps.toBeRatedCount !== this.props.toBeRatedCount ||
      !deepEqual(prevProps.registeredCourses, this.props.registeredCourses)
    ) {
      this.update({
        activeCourses:
          this.state.activeTab === 0
            ? this.props.registeredCourses
            : this.props.archivedCourses,
      });
    }
  };

  // ------------------------------

  tabClickHandler = (tabId) => {
    if (tabId === 0) {
      this.update({
        activeTab: tabId,
        activeCourses: this.props.registeredCourses,
      });
    } else {
      this.update({
        activeTab: tabId,
        activeCourses: this.props.archivedCourses,
      });
    }
  };

  // ------------------------------

  closeErrorHandler = () => {
    this.update({
      message: null,
    });
  };

  // ------------------------------

  archiveHandler = async (registration) => {
    try {
      await this.update({ wait: true });
      await this.props.archiveRegistration(registration);
      await this.update({
        wait: false,
        alertSuccess:
          this.state.activeTab === 0
            ? this.props.t("registration_archived")
            : this.props.t("registration_unarchived"),
        errorModal: false,
      });
    } catch (error) {
      logger.error(error);
      this.update({
        wait: false,
        errorModal: true,
        message: error.message,
      });
    }
  };

  // ------------------------------

  openShareCourseModal = async (id) => {
    try {
      await this.update({ wait: true });
      const foundCourse = await this.props.fetchCourse(id);

      if (foundCourse) {
        await this.update({
          wait: false,
          showShareCourseModal: true,
        });
      } else {
        await this.update({
          wait: false,
          alertSuccess: this.props.t("registration_course_error"),
          severity: "warning",
        });
      }
    } catch (error) {
      logger.error(error);
      this.update({
        wait: false,
        errorModal: true,
        message: error.message,
      });
    }
  };

  // ------------------------------

  openStaffRegistered = async (reg) => {
    try {
      await this.update({ wait: true });
      await this.props.fetchStaffRegistered(reg.course_id, reg.provider_id);
      await this.update({ wait: false, showStaffRegisteredModal: true });
    } catch (error) {
      logger.error(error);
      this.update({
        showStaffRegisteredModal: false,
        wait: false,
        errorModal: true,
        message: error.message,
      });
    }
  };

  // ------------------------------

  courseHandler = (courseId, providerId) => {
    this.props.history.push(`details?p=${providerId}&c=${courseId}`);
  };

  // ------------------------------

  viewHandler = async (coursId, providerId) => {
    try {
      await this.update({ wait: true });
      const foundCourse = await this.props.fetchCourse(coursId, providerId);

      if (foundCourse) {
        await this.update({ wait: false, showCourseModal: true });
      } else {
        await this.update({
          wait: false,
          alertSuccess: this.props.t("registration_course_error"),
          severity: "warning",
        });
      }
    } catch (error) {
      logger.error(error);
      this.update({
        showCourseModal: false,
        wait: false,
        errorModal: true,
        message: error.message,
      });
    }
  };

  // ------------------------------

  handoutHandler = async (courseId, providerId) => {
    try {
      await this.update({ wait: true });
      await this.props.fetchRegistrationContent(courseId, providerId);
      await this.update({ wait: false, showHandoutModal: true });
    } catch (error) {
      logger.error(error);
      this.update({
        showHandoutModal: false,
        wait: false,
        errorModal: true,
        message: error.message,
      });
    }
  };

  // ------------------------------

  openCertHandler = async (reg) => {
    try {
      if (!reg.certificate_uri) {
        await this.update({
          wait: true,
          waitMessage: this.props.t('g_this_may_take_a_few_minutes')
        });
        const regResponse = await this.props.openCertificate(
          reg.id,
          reg.provider_id,
          reg.course_id
        );

        if (regResponse) {
          await this.update({
            wait: false,
            showDocumentListModal: true,
            selectedRegistrationCard: regResponse.id ? regResponse : reg,
            documentListModalError: regResponse.id
              ? ""
              : this.resolveCertificateError(regResponse),
          });
        }
      } else {
        await this.update({
          showDocumentListModal: true,
          selectedRegistrationCard: reg,
        });
      }
    } catch (error) {
      logger.error(error);
      this.update({
        showDocumentListModal: false,
        wait: false,
        errorModal: true,
        message: error.message,
      });
    }
  };

  // ------------------------------

  resolveCertificateError = (error) => {
    switch (error) {
      case "ERROR_NOT_EXPIRED":
        return this.props.t("certificate_error_not_yet_available");
      case "ERROR_NOT_ATTENDED":
        return this.props.t("certificate_error_not_attended");
      case "ERROR_SURVEY_NOT_COMPLETED":
        return this.props.t("certificate_error_survey_not_completed");
      case "ERROR_NO_CERTIFICATE":
        return this.props.t("certificate_error_no_certificate");
      default:
        return "";
    }
  };

  // ------------------------------

  instructionHandler = async (courseId, providerId) => {
    try {
      await this.update({ wait: true });
      await this.props.fetchRegistrationContent(courseId, providerId);
      await this.update({ wait: false, showInstructionModal: true });
    } catch (error) {
      logger.error(error);
      this.update({
        showInstructionModal: false,
        wait: false,
        errorModal: true,
        message: error.message,
      });
    }
  };

  // ------------------------------

  openInboxChat = async (id, provider_Id) => {
    try {
      await this.update({ wait: true });

      const foundCourse = await this.props.fetchCourse(id, provider_Id);

      if (foundCourse) {
        const conversation = this.props.conversations.find(
          (c) =>
            c.provider_uid === foundCourse.provider_id && c.by_user === true
        );

        await this.props.fetchMessages(conversation);

        if (conversation) {
          await this.props.updateLastViewed(conversation.id);
        }

        await this.update({
          wait: false,
          showChatModal: true,
          activeConversation: conversation,
        });
      } else {
        await this.update({
          wait: false,
          alertSuccess: this.props.t("registration_course_error"),
          severity: "warning",
          activeConversation: null,
        });
      }
    } catch (error) {
      logger.error(error);
      this.update({
        showChatModal: false,
        wait: false,
        errorModal: true,
        activeConversation: null,
        message: error.message,
      });
    }
  };

  // ------------------------------

  openRating = async (reg) => {
    try {
      await this.update({ wait: false });
      await this.update({
        wait: false,
        selectedRegistrationCard: reg,
        showRatingModal: true,
      });
    } catch (error) {
      logger.error(error);
      this.update({
        showRatingModal: false,
        wait: false,
        errorModal: true,
        message: error.message,
      });
    }
  };

  // ------------------------------

  openLocation = async (reg) => {
    try {
      await this.update({ wait: false });

      const foundCourse = await this.props.fetchCourse(
        reg.course_id,
        reg.provider_id
      );

      if (foundCourse) {
        window.open(foundCourse.googleMapUrl());
      } else {
        await this.update({
          wait: false,
          alertSuccess: this.props.t("registration_course_error"),
          severity: "warning",
        });
      }
    } catch (error) {
      logger.error(error);
      this.update({
        wait: false,
        errorModal: true,
        message: error.message,
      });
    }
  };

  // ------------------------------

  rateMyProviderHandler = async (rating, comment) => {
    await this.update({ wait: true, showRatingModal: false });

    if (rating) {
      try {
        await this.props.rateMyProvider(
          this.state.selectedRegistrationCard.id,
          rating,
          comment,
          this.state.selectedRegistrationCard.course_id
        );
      } catch (error) {
        logger.error(error);
        this.update({
          wait: false,
          errorModal: true,
          message: error.message,
        });
      }
    }

    await this.update({ wait: false });
  };

  // ------------------------------

  deleteCard = async (reg) => {
    try {
      await this.update({ wait: false });
      this.props.removeRegistrationCard(reg.id);
      await this.update({
        wait: false,
      });
    } catch (error) {
      logger.error(error);
      this.update({
        wait: false,
        errorModal: true,
        message: error.message,
      });
    }
  };

  // ------------------------------

  skipRatingHandler = async () => {
    try {
      await this.update({ wait: true, showRatingModal: false });
      await this.props.skipRating(this.state.selectedRegistrationCard.id);
      await this.update({ wait: false });
    } catch (error) {
      logger.error(error);
      this.update({
        wait: false,
        errorModal: true,
        message: error.message,
      });
    }
  };

  // ------------------------------

  loadMoreHandler = async () => {
    try {
      await this.update({
        isLoadingMore: true,
      });

      if (this.state.activeTab === 0) {
        const page = this.state.page ? this.state.page + 1 : 2;
        this.update({ page: page });
        this.props.fetchRegistration(page);
      } else {
        const archivedPage = this.state.archivedPage
          ? this.state.archivedPage + 1
          : 2;
        this.update({ archivedPage: archivedPage });
        this.props.fetchArchivedRegistration(archivedPage);
      }

      await this.update({
        isLoadingMore: false,
      });
    } catch (error) {
      logger.error(error);
      await this.update({
        wait: false,
        errorModal: true,
        message: error.message,
      });
    }
  };

  // ------------------------------

  openSurvey = async (reg) => {
    try {
      await this.update({
        wait: true,
        waitMessage: this.props.t("g_loading"),
        selectedRegistrationCard: reg,
      });
      await this.props.openSurvey(reg.survey_token);
      await this.update({
        wait: false,
        showSurveyPreviewModal: true,
        waitMessage: "",
      });
    } catch (error) {
      logger.error(error);
      await this.update({
        wait: false,
        errorModal: true,
        message: error.message,
        waitMessage: "",
      });
    }
  };

  // ------------------------------

  saveSurveyResultsHandler = async (results) => {
    try {
      await this.update({
        wait: true,
        waitMessage: this.props.t("cart_processing"),
      });
      await this.props.saveSurveyResults(
        this.state.selectedRegistrationCard.survey_token,
        results.data
      );
      await this.props.refreshRegistration(
        this.state.selectedRegistrationCard.id
      );
      await this.update({
        wait: false,
        showSurveyPreviewModal: false,
        waitMessage: "",
      });
    } catch (error) {
      logger.error(error);
      this.update({ wait: false, error: error.message, waitMessage: "" });
    }
  };

  // ------------------------------

  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        {this.state.wait && <Spinner message={this.state.waitMessage} />}
        {this.state.showSurveyPreviewModal && (
          <SurveyPreviewModal
            actionButtonLeftAlign
            open={this.state.showSurveyPreviewModal}
            surveyJson={{
              ...this.props.surveyJson,
              completedHtml: `<h3>${this.props.t(
                "survey_thank_you"
              )}</h3> <h5>${this.props.t(
                "certificate_will_be_available"
              )}</h5><br/><br/>`,
            }}
            title={this.props.t("registration_evaluation")}
            okButtonText={this.props.t("g_close")}
            onClose={() => {
              this.update({ showSurveyPreviewModal: false });
            }}
            onComplete={this.saveSurveyResultsHandler}
          />
        )}
        {this.state.showRatingModal && (
          <RatingModal
            courseTitle={this.state.selectedRegistrationCard.course_title}
            open={this.state.showRatingModal}
            onSubmitRating={this.rateMyProviderHandler}
            onSkipRating={this.skipRatingHandler}
          />
        )}
        {this.state.showChatModal && (
          <InboxChatModal
            providerName={this.props.selectCourse.provider_name}
            selectedConversation={this.state.activeConversation}
            showAllConversations={false}
            conversations={this.props.conversations}
            messages={this.props.messages}
            open={this.state.showChatModal}
            onSendMessage={(message) => {
              try {
                this.props.sendMessage(
                  this.state.activeConversation,
                  message,
                  this.props.selectCourse.provider_id,
                  this.props.selectCourse.provider_name
                );
              } catch (error) {
                logger.error(error);
                this.update({
                  showChatModal: false,
                  errorModal: true,
                  message: error.message,
                });
              }
            }}
            onClose={() => {
              this.update({ showChatModal: false });
              if (
                this.state.activeConversation &&
                this.state.activeConversation.id
              ) {
                this.props.stopInboxMessagesListener(
                  this.state.activeConversation.id
                );
              }
            }}
            onSelectConversation={() => { }}
          />
        )}
        <InfoModal
          isErrorModal={this.state.errorModal}
          title={
            this.state.errorModal
              ? this.props.t("error_occurred")
              : this.state.messageTitle
          }
          message={this.state.message}
          open={this.state.message !== null}
          onClose={this.closeErrorHandler}
        />
        {this.state.alertSuccess !== null && (
          <AlertSuccess
            message={this.state.alertSuccess}
            severity={this.state.severity}
            open={this.state.alertSuccess !== null}
            onClose={() => {
              this.update({ alertSuccess: null, severity: null });
            }}
          />
        )}
        {this.state.showCourseModal && this.props.selectCourse && (
          <CourseViewModal
            currency={this.props.currency}
            course={this.props.selectCourse}
            rates={this.props.rates}
            onClose={() => {
              this.update({ showCourseModal: false });
            }}
          />
        )}
        {this.state.showInstructionModal && (
          <RegistrationContentModal
            readOnly
            open={this.state.showInstructionModal}
            course={this.props.selectCourse}
            email_instruction={this.props.email_instruction}
            onClose={async () => {
              await this.update({
                showInstructionModal: null,
              });
            }}
          />
        )}
        {this.state.showHandoutModal && (
          <HandoutModal
            readOnly
            open={this.state.showHandoutModal}
            course={this.props.selectCourse}
            selected_handout={this.props.selected_handout}
            selected_handout_delivery={this.props.selected_handout_delivery}
            onClose={async () => {
              await this.update({
                showHandoutModal: false,
              });
            }}
          />
        )}
        {this.state.showDocumentListModal && (
          <DocumentListModal
            open={this.state.showDocumentListModal}
            registration={this.state.selectedRegistrationCard}
            documentListModalError={this.state.documentListModalError}
            onClose={async () => {
              await this.update({
                showDocumentListModal: false,
                documentListModalError: null,
              });
            }}
          />
        )}
        {this.state.showShareCourseModal && (
          <ShareCourseModal
            open={this.state.showShareCourseModal}
            course={this.props.selectCourse}
            onClose={async () => {
              await this.update({
                showShareCourseModal: false,
              });
            }}
          />
        )}

        {this.state.showStaffRegisteredModal && (
          <StaffRegisteredModal
            open={this.state.showStaffRegisteredModal}
            registrants={this.props.staffRegistered}
            onClose={async () => {
              await this.update({
                showStaffRegisteredModal: false,
              });
            }}
          />
        )}
        <MatTabs
          onClick={this.tabClickHandler}
          activeTab={this.state.activeTab}
          centered={false}
          headerTitle={this.props.t("g_bar_my_courses")}
        >
          <Tab label={this.props.t("registration_tab_courses")} />
          <Tab label={this.props.t("registration_tab_archived")} />
        </MatTabs>

        {!this.state.activeCourses ||
          (this.state.activeCourses.length === 0 && this.state.wait === false && (
            <div style={{ backgroundColor: "white", paddingTop: "150px" }}>
              <Zoom
                in={true}
                style={{ transitionDelay: true ? "100ms" : "0ms" }}
              >
                <Empty
                  column
                  image={this.state.activeTab === 0 ? regImg : archivedImg}
                  message={
                    <p style={{ padding: "10px" }}>
                      {this.state.activeTab === 0
                        ? this.props.t("registration_no_courses_here_long")
                        : this.props.t("registration_no_archived_here_long")}
                    </p>
                  }
                  buttonIcon={<SearchIcon />}
                  buttonLabel={this.props.t("g_bar_search")}
                  onClick={
                    this.state.activeTab === 0
                      ? () => {
                        this.props.history.push("/search");
                      }
                      : null
                  }
                />
              </Zoom>
            </div>
          ))}
        {!this.state.activeCourses ||
          (this.state.activeCourses.length > 0 && (
            <div>
              <div className={styles.cards}>
                <CeSummary
                  year={CURRENT_YEAR}
                  completed={this.props.completedCE}
                  pending={this.props.pendingCE}
                  onClick={() => {
                    this.props.history.push("/ce")
                  }}
                />
                {this.state.activeCourses.map((c, index) => (
                  <RegistrationItem
                    key={c.id}
                    image={c.image}
                    archived={c.archived}
                    course_id={c.course_id}
                    course_title={c.course_title}
                    casting={c.casting}
                    date={c.renderStartDate()}
                    registered_date={c.renderRegistrationDate()}
                    unregistered_date={c.renderUnregistrationDate()}
                    remaining={c.resolveDaysRemaining()}
                    provider_instruction={c.provider_instruction}
                    requiresRating={c.requiresRating()}
                    surveyToken={c.survey_token}
                    certificateEn={c.certificate_uri}
                    certificateFr={c.certificate_uri_fr}
                    provider={
                      this.props.provider
                        ? this.props.provider.find(
                          (p) => p.id === c.provider_id
                        )
                        : ""
                    }
                    onSelectCourse={this.courseHandler.bind(
                      this,
                      c.course_id,
                      c.provider_id
                    )}
                    onViewCourse={this.viewHandler.bind(
                      this,
                      c.course_id,
                      c.provider_id
                    )}
                    onViewHandout={this.handoutHandler.bind(
                      this,
                      c.course_id,
                      c.provider_id
                    )}
                    onViewInstruction={this.instructionHandler.bind(
                      this,
                      c.course_id,
                      c.provider_id
                    )}
                    onArchive={this.archiveHandler.bind(this, c)}
                    onShareCourse={this.openShareCourseModal.bind(
                      this,
                      c.course_id
                    )}
                    onOpenInboxChat={this.openInboxChat.bind(
                      this,
                      c.course_id,
                      c.provider_id
                    )}
                    onOpenRating={this.openRating.bind(this, c)}
                    onOpenLocation={this.openLocation.bind(this, c)}
                    onDelete={this.deleteCard.bind(this, c)}
                    onListRegistrants={this.openStaffRegistered.bind(this, c)}
                    onOpenSurvey={this.openSurvey.bind(this, c)}
                    onOpenCertificate={this.openCertHandler.bind(this, c)}
                  />
                ))}
              </div>

              <div className={styles.listContainer}>
                <div className={styles.loadMoreContainer}>
                  <LoadMore
                    title={this.props.t("g_load_more")}
                    loading={this.state.isLoadingMore}
                    hasMore={
                      this.state.activeTab === 0
                        ? this.props.hasMore
                        : this.props.hasMoreArchived
                    }
                    onLoadMore={this.loadMoreHandler}
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  }
}

// ------------------------------

const mapStateToProps = (state) => {
  const mobileSetting = state.setting.mobileSetting;

  return {
    pendingCE: state.ce.pending,
    completedCE: state.ce.completed,

    email_instruction: state.courses.email_instruction,
    selected_handout: state.courses.selected_handout,
    selected_handout_delivery: state.courses.selected_handout_delivery,
    currency: findCurrency(mobileSetting.currency),
    mobileSetting: mobileSetting,
    selectCourse: state.courses.select_course,
    provider: state.provider.provider,
    toBeRatedCount: state.registration.toBeRatedCount,
    rates: state.rate.rate,
    conversations: state.inbox.conversations,
    messages: state.inbox.messages,

    registeredCourses: state.registration.registration,
    archivedCourses: state.registration.registrationArchived,
    hasMore: state.registration.hasMore,
    hasMoreArchived: state.registration.hasMoreArchived,
    staffRegistered: state.registration.staffRegistered,

    surveyJson: state.survey.openSurvey,
  };
};

// ------------------------------

const mapDispatchToProps = (dispatch) => {
  return {
    refreshRegistration: (regId) =>
      dispatch(registrationActions.refreshRegistration(regId)),

    fetchStaffRegistered: (cId, pId) =>
      dispatch(registrationActions.fetchStaffRegistered(cId, pId)),
    removeRegistrationCard: (regId) =>
      dispatch(registrationActions.removeRegistrationCard(regId)),
    skipRating: (regId) => dispatch(registrationActions.skipRating(regId)),
    rateMyProvider: (regId, rating, comment, courseId) =>
      dispatch(
        registrationActions.rateMyProvider(regId, rating, comment, courseId)
      ),
    fetchMobileSetting: () => dispatch(settingActions.fetchMobileSetting()),
    fetchCourse: (courseId, providerId) =>
      dispatch(courseActions.fetchCourse(courseId, providerId)),
    fetchProviders: () => dispatch(providerActions.fetchProviders()),
    fetchRegistration: (page, archivedPage) =>
      dispatch(registrationActions.fetchRegistration(page, archivedPage)),
    fetchArchivedRegistration: (archivedPage) =>
      dispatch(registrationActions.fetchArchivedRegistration(archivedPage)),
    archiveRegistration: (registration) =>
      dispatch(registrationActions.archiveRegistration(registration)),
    fetchRegistrationContent: (courseId, providerId) =>
      dispatch(courseActions.fetchRegistrationContent(courseId, providerId)),
    fetchRates: () => dispatch(rateActions.fetchRates()),
    fetchUserProfile: () => dispatch(profileActions.fetchMobileUserProfile()),
    fetchMessages: (conversation) =>
      dispatch(inboxActions.fetchMessages(conversation)),
    sendMessage: (conversation, message, providerId, providerName) =>
      dispatch(
        inboxActions.sendMessage(
          conversation,
          message,
          providerId,
          providerName
        )
      ),
    updateLastViewed: (convoId) =>
      dispatch(inboxActions.updateLastViewed(convoId)),
    stopInboxMessagesListener: (convoId) =>
      dispatch(inboxActions.stopInboxMessagesListener(convoId)),
    openCertificate: (regId, providerId, courseId) =>
      dispatch(
        registrationActions.openCertificate(regId, providerId, courseId)
      ),
    openSurvey: (code) => dispatch(surveyActions.openSurvey(code)),
    saveSurveyResults: (code, results) =>
      dispatch(surveyActions.saveSurveyResults(code, results)),
    fetchCeByYear: (fromYear, toYear, fromDate, toDate, badge) =>
      dispatch(ceActions.fetchCeByYear(fromYear, toYear, fromDate, toDate, badge)),
  };
};

export default withTranslation("translations")(
  connect(mapStateToProps, mapDispatchToProps)(MyRegistrationsScreen)
);
