import { handleErrorResponse } from "../../globals/Error";
import { dbName } from "../../globals/ApiKeys";
import Student from "../../model/Student";
import firebase from "firebase/app";
import "firebase/firestore";
export const SET_STUDENTS = "SET_STUDENTS";
export const UNREGISTER_STUDENT = "UNREGISTER_STUDENT";
export const STUDENT_CERT_SENT = "STUDENT_CERT_SENT";
export const STUDENT_SURVEY_SENT = "STUDENT_SURVEY_SENT";

// ------------------------------

export const updateLastViewed = (courseId) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const lastViewed = new Date();

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/registration/${userId}/course/${courseId}?updateMask.fieldPaths=new_registration`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: { new_registration: { integerValue: 0 } },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }
  };
};

// ------------------------------

export const fetchStudentsByCharge = (chargeId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const userId = getState().auth.userId;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/registration/${userId}:runQuery`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          structuredQuery: {
            from: [{ collectionId: "course" }],
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    const loadedStudents = [];
    for (const documents of resData) {
      const course = documents.document;
      if (course && course.name) {
        const courseNameArray = course.name.split("/");
        const id = courseNameArray[courseNameArray.length - 1];
        const students = await dispatch(fetchStudentsByCourseId(id));
        loadedStudents.push(...students);
      }
    }

    dispatch({
      type: SET_STUDENTS,
      students: {
        students: loadedStudents.filter(
          (student) => student.charge === chargeId
        ),
      },
    });
  };
};

// ------------------------------

export const fetchStudents = (courseId) => {
  return async (dispatch, getState) => {
    const merged = await dispatch(fetchStudentsByCourseId(courseId));

    dispatch({
      type: SET_STUDENTS,
      students: {
        students: merged,
      },
    });
  };
};

// ------------------------------

export const fetchStudentsByCourseId = (courseId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const userId = getState().auth.userId;

    const arrResponse = await Promise.all([
      fetch(
        `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/registration/${userId}/course/${courseId}:runQuery`,
        {
          method: "POST",
          headers: {
            "Content-Type": "applications/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            structuredQuery: {
              from: [{ collectionId: "students" }],
              //limit: 25,
            },
          }),
        }
      ),
      fetch(
        `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/registration/${userId}/course/${courseId}:runQuery`,
        {
          method: "POST",
          headers: {
            "Content-Type": "applications/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            structuredQuery: {
              from: [{ collectionId: "unregistered" }],
              //limit: 25,
            },
          }),
        }
      ),
    ]);

    if (!arrResponse[0].ok) {
      handleErrorResponse(await arrResponse[0].json());
    }

    if (!arrResponse[1].ok) {
      handleErrorResponse(await arrResponse[1].json());
    }

    const resData = await arrResponse[0].json();
    const resUnregisteredData = await arrResponse[1].json();
    const loadedStudents = loadStudents(resData, false, courseId);

    const sortedStudents = loadedStudents.sort((a, b) => {
      if (a.registeredDate && b.registeredDate) {
        return a.registeredDate.getTime() > b.registeredDate.getTime()
          ? -1
          : 1;
      }
      return 1;
    })

    const merged = sortedStudents.concat(
      loadStudents(resUnregisteredData, true, courseId)
    );

    return merged;
  };
};

// ------------------------------

const loadStudents = (resData, unRegistered, courseId) => {
  const loadedStudents = [];

  for (const documents of resData) {
    const student = documents.document;

    if (student && student.name) {
      const studentNameArray = student.name.split("/");
      const studentId = studentNameArray[studentNameArray.length - 1];

      loadedStudents.push(
        new Student(
          studentId,
          student.fields.first.stringValue,
          student.fields.last.stringValue,
          student.fields.license.stringValue,
          student.fields.occupation.stringValue,
          student.fields.email.stringValue,
          student.fields.registeredById.stringValue,
          student.fields.registeredByName.stringValue,
          student.fields.registrationOption.stringValue,
          new Date(student.fields.registeredDate.timestampValue),
          student.fields.charge_id ? student.fields.charge_id.stringValue : "",
          student.fields.orderRefNumber
            ? student.fields.orderRefNumber.stringValue
            : "",
          unRegistered,
          student.fields.unregistered_date
            ? new Date(student.fields.unregistered_date.timestampValue)
            : null,
          student.fields.unregistered_reason
            ? student.fields.unregistered_reason.stringValue
            : "",
          courseId,
          student.fields.certificate_sent
            ? new Date(student.fields.certificate_sent.timestampValue)
            : null,
          student.fields.survey_sent
            ? new Date(student.fields.survey_sent.timestampValue)
            : null,
          student.fields.survey_completed
            ? new Date(student.fields.survey_completed.timestampValue)
            : null,
          student.fields.member_id
            ? student.fields.member_id.stringValue
            : null,
        )
      );
    }
  }

  return loadedStudents;
};

// ------------------------------

export const unregisterStudent = (courseId, studentId, reason) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const userId = getState().auth.userId;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/registration/${userId}/course/${courseId}/students/${studentId}?updateMask.fieldPaths=unregistered_reason`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            unregistered_reason: {
              stringValue: reason,
            },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    // ------------------------------

    await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/registration/${userId}/course/${courseId}/students/${studentId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch({
      type: UNREGISTER_STUDENT,
      studentId: studentId,
      reason: reason,
    });
  };
};

// ------------------------------

export const emailCertificate = (courseId, studentIds) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/sendEmailCertificate`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: { courseId: courseId, studentIds: studentIds },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: STUDENT_CERT_SENT,
      courseId: courseId,
      studentIds: studentIds,
    });
  };
};

// ------------------------------

export const sendProviderNotification = (courseId, message) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/sendProviderNotification`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: { courseId: courseId, message: message },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }
  };
};

// ------------------------------

export const resendSurvey = (courseId, studentId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/resendSurvey`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: { courseId: courseId, studentId: studentId },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: STUDENT_SURVEY_SENT,
      courseId: courseId,
      studentId: studentId,
    });
  };
};

// ------------------------------

export const downloadCertificate = (courseId, studentId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/downloadCertificate`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: { courseId: courseId, studentId: studentId },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    if (resData && resData.result && resData.result.error) {
      return resData.result.errorType;
    } else if (resData && resData.result && resData.result.success) {
      return {
        success: true,
        certificate_url_en: resData.result.certificate_url_en,
        certificate_url_fr: resData.result.certificate_url_fr
      }
    }
  };
};

// ------------------------------