import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as settingActions from "../../store/actions/setting";
import * as studentActions from "../../store/actions/student";
import * as studentOrderActions from "../../store/actions/studentorder";
import * as attendanceActions from "../../store/actions/attendance";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Button from "@material-ui/core/Button";
import Spinner from "../../components/ui/spinner/Spinner";
import MatAppBar from "../../components/ui/appBar/MatAppBar";
import InfoModal from "../../components/modals/infoModal/InfoModal";
import ConfirmModal from "../../components/modals/confirmModal/ConfirmModal";
import ProviderMessageModal from "../../components/modals/providerMessageModal/ProviderMessageModal";
import StudentTable from "../../components/items/studentItems/StudentTable";
import CertificatePreviewModal from "../../components/modals/certificatePreviewModal/CertificatePreviewModal";
import AlertSuccess from "../../components/ui/snackbar/AlertSuccess";
import { EXPIRED } from "../../globals/Data";
import * as logger from "../../globals/Logger";

class StudentsScreen extends Component {
  state = {
    wait: false,
    waitNoRender: false,
    waitMessage: "",
    openCertificatePreviewModal: false,
    error: null,
    message: null,
    alertSuccess: null,
    studentsToSendCert: null,
    openProviderMessageModal: false,
    studentToSendSurvey: null,
    openConfirmSendSurveyModal: false,
    severity: null,

    confirmAttendanceModal: {
      open: false,
      title: "",
      text: "",
    },

    activateAttendanceParam: false,
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      window.scrollTo(0, 0);
      await this.update({ error: null, wait: true });

      if (this.props.course) {
        await Promise.all([
          this.props.updateLastViewed(this.props.course.id),
          this.props.fetchStudents(this.props.course.id),
          this.props.fetchAttendanceActiveFlag(this.props.course.id),
        ]);
      }
      await this.update({ wait: false });
    } catch (error) {
      logger.error(error);
      await this.update({
        wait: false,
        error: error.message,
      });
    }
  };

  // ------------------------------

  editStudentOrderHandler = async (student) => {
    await this.props.setChargeId(student.charge, student.courseId);
    this.props.history.push("/studentorder");
  };

  // ------------------------------

  issueCertHandler = async (students) => {
    await this.update({
      openCertificatePreviewModal: true,
      studentsToSendCert: students,
    });
  };

  // ------------------------------

  resendSurveyHandler = async (student) => {
    await this.update({
      openConfirmSendSurveyModal: true,
      studentToSendSurvey: student,
    });
  };

  // ------------------------------

  resendSurveyConfirmHandler = async (answer) => {
    if (answer === "ok") {
      try {
        await this.update({
          openConfirmSendSurveyModal: false,
          waitNoRender: true,
          waitMessage: "Sending Survey",
        });

        await this.props.resendSurvey(
          this.state.studentToSendSurvey.courseId,
          this.state.studentToSendSurvey.id
        );

        await this.update({
          waitNoRender: false,
          waitMessage: "",
          alertSuccess: "Survey sent",
          studentToSendSurvey: null,
        });
      } catch (error) {
        logger.error(error);
        await this.update({
          openConfirmSendSurveyModal: false,
          studentToSendSurvey: null,
          waitNoRender: false,
          waitMessage: "",
          message: error.message,
        });
      }
    } else {
      this.update({
        openConfirmSendSurveyModal: false,
        studentToSendSurvey: null,
      });
    }
  };

  // ------------------------------

  confirmSendCertificates = async (answer) => {
    if (answer === "download") {
      try {
        await this.update({
          openCertificatePreviewModal: false,
          waitNoRender: true,
          waitMessage: "Downloading, can take a few minutes.",
        });

        const studentIds = this.state.studentsToSendCert.map((s) => {
          return s.id;
        });

        const response = await this.props.downloadCertificate(this.props.course.id, studentIds[0]);

        if (response && response.success) {
          if (response.certificate_url_en)
            window.open(response.certificate_url_en, "_blank", "noreferrer");

          if (response.certificate_url_fr)
            window.open(response.certificate_url_fr, "_blank", "noreferrer");

          await this.update({
            waitNoRender: false,
            waitMessage: "",
            alertSuccess: "Download completed",
          });
        }
        else {
          await this.update({
            waitNoRender: false,
            waitMessage: "",
            alertSuccess: "Download failed " + response,
            severity: "error"
          });
        }
      } catch (error) {
        logger.error(error);
        await this.update({
          waitNoRender: false,
          waitMessage: "",
          message: error.message,
        });
      }
    }
    else if (answer === "ok") {
      try {
        await this.update({
          openCertificatePreviewModal: false,
          waitNoRender: true,
          waitMessage: "Sending certificates",
        });

        const validStudents = this.state.studentsToSendCert.filter(
          (s) => !s.unregisteredDate
        );

        const studentIds = validStudents.map((s) => {
          return s.id;
        });

        await this.props.emailCertificate(this.props.course.id, studentIds);

        await this.update({
          waitNoRender: false,
          waitMessage: "",
          alertSuccess: "Certificates sent",
        });
      } catch (error) {
        logger.error(error);
        await this.update({
          waitNoRender: false,
          waitMessage: "",
          message: error.message,
        });
      }
    } else {
      this.update({
        openCertificatePreviewModal: false,
      });
    }
  };

  // ------------------------------

  messageAllHandler = async () => {
    if (this.props.course.activation === EXPIRED) {
      await this.update({
        alertSuccess:
          "Send Message (All Students) - permitted up to 30 days on expired course",
        severity: "info",
      });
    }

    await this.update({
      openProviderMessageModal: true,
    });
  };

  // ------------------------------

  confirmMessageAllHandler = async (answer, message) => {
    try {
      if (answer === "ok") {
        await this.update({
          openProviderMessageModal: false,
          waitNoRender: true,
          waitMessage: "Sending your message",
        });

        await this.props.sendProviderNotification(
          this.props.course.id,
          message
        );

        await this.update({
          waitNoRender: false,
          waitMessage: "",
          alertSuccess: "Message sent",
        });
      }
    } catch (error) {
      logger.error(error);
      await this.update({
        openProviderMessageModal: false,
        waitNoRender: false,
        waitMessage: "",
        error: error.message,
      });
    }

    this.update({
      openProviderMessageModal: false,
    });
  };

  // ------------------------------

  setAttendanceActiveHandler = async (isActive) => {
    if (isActive) {
      await this.update({
        confirmAttendanceModal: {
          open: true,
          title: "Activate Attendance",
          text: "Activating allows you to manage your student attendance on the day of your course.  Course surveys and certificate will be restriced to students marked as present on the attendance list.",
        },
        activateAttendanceParam: isActive,
      });
    } else {
      await this.update({
        confirmAttendanceModal: {
          open: true,
          title: "Deactivate Attendance",
          text: "Deactivating will remove any changes made to your attendance list.  Course surveys and certificates will be sent to students without attendance validation.",
        },
        activateAttendanceParam: isActive,
      });
    }
  };

  // ------------------------------

  updateSettingColumns = async (columns) => {
    try {
      this.props.updateSettingColumns(columns);
    } catch (error) {
      logger.error(error);
    }
  };

  // ------------------------------

  confirmSetAttendanceActive = async (answer) => {
    const resetConfirmModal = {
      open: false,
      title: "",
      text: "",
    };

    if (answer === "ok") {
      try {
        await this.update({
          confirmAttendanceModal: resetConfirmModal,
          waitNoRender: true,
          waitMessage: this.state.activateAttendanceParam
            ? "Activating Attendance"
            : "Deactivating Attendance",
        });

        await this.props.activateAttendance(
          this.state.activateAttendanceParam,
          this.props.course.id
        );

        await this.update({
          waitMessage: "",
          waitNoRender: false,
          alertSuccess: this.state.activateAttendanceParam
            ? "Attendence Activated"
            : "Attendance Deactivated",
        });
      } catch (error) {
        logger.error(error);

        await this.update({
          waitMessage: "",
          waitNoRender: false,
          message: error.message,
        });
      }
    } else {
      this.update({
        confirmAttendanceModal: resetConfirmModal,
      });
    }
  };

  // ------------------------------

  takeAttendance = () => {
    this.props.history.push({
      pathname: "/attendance",
      state: {
        courseId: this.props.course.id,
        providerId: this.props.course.provider_id,
      },
    });
  };

  // ------------------------------

  render() {
    if (this.props.action !== "PUSH") {
      return <Redirect to="/courses" />;
    }

    if (this.state.wait) {
      return <Spinner />;
    }

    // ------------------------------

    let title = "";
    if (this.props.course) {
      if (this.props.course.format_start_date()) {
        title = this.props.course
          ? "Students - " +
          this.props.course.title +
          " (Course Date: " +
          this.props.course.format_start_date() +
          ")"
          : "";
      } else {
        title = this.props.course
          ? "Students - " + this.props.course.title
          : "";
      }
    }

    // ------------------------------

    return (
      <div style={{ background: "white" }}>
        {this.state.waitNoRender && (
          <Spinner message={this.state.waitMessage} />
        )}

        {this.state.openProviderMessageModal && (
          <ProviderMessageModal
            open={this.state.openProviderMessageModal}
            onClose={this.confirmMessageAllHandler}
          />
        )}

        {this.state.confirmAttendanceModal.open && (
          <ConfirmModal
            title={this.state.confirmAttendanceModal.title}
            message={this.state.confirmAttendanceModal.text}
            open={this.state.confirmAttendanceModal.open}
            onClose={this.confirmSetAttendanceActive}
            okButtonText={
              this.state.activateAttendanceParam ? "Activate" : "Deactivate"
            }
          />
        )}

        {this.state.openConfirmSendSurveyModal && (
          <ConfirmModal
            title="Resend Survey"
            message={`Are you sure you wish to send to (${this.state.studentToSendSurvey.first} ${this.state.studentToSendSurvey.last} / License: ${this.state.studentToSendSurvey.license})?`}
            open={this.state.openConfirmSendSurveyModal}
            onClose={this.resendSurveyConfirmHandler}
          />
        )}

        {this.state.openCertificatePreviewModal && (
          <CertificatePreviewModal
            header={this.props.cert_header}
            footer={this.props.cert_footer}
            profile={this.props.profile}
            course={this.props.course}
            onClose={this.confirmSendCertificates}
          />
        )}

        <InfoModal
          isErrorModal={true}
          title="An error has occured"
          message={this.state.error}
          open={this.state.error !== null}
          onClose={() => {
            this.update({ error: null });
          }}
        />

        {this.state.alertSuccess !== null && (
          <AlertSuccess
            severity={this.state.severity}
            message={this.state.alertSuccess}
            open={this.state.alertSuccess !== null}
            onClose={() => {
              this.update({ alertSuccess: null, severity: null });
            }}
          />
        )}

        <MatAppBar
          noFixed
          static
          left={
            <Button
              color="inherit"
              style={{ color: "black" }}
              onClick={this.props.history.goBack}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </Button>
          }
        />

        <StudentTable
          providerId={this.props.course ? this.props.course.provider_id : -1}
          display={this.props.setting.student_table_display}
          updateSettingColumns={this.updateSettingColumns}
          title={title}
          data={this.props.students}
          editStudentOrder={this.editStudentOrderHandler}
          issueCert={this.issueCertHandler}
          resendSurvey={this.resendSurveyHandler}
          messageAll={this.messageAllHandler}
          // Attendance
          isAttendanceActive={this.props.isAttendanceActive}
          hasAttendance={this.props.course && !this.props.course.is_video}
          setAttendanceActive={this.setAttendanceActiveHandler}
          takeAttendance={this.takeAttendance}
        />
      </div>
    );
  }
}

// ------------------------------

const mapStateToProps = (state, props) => {
  let course = state.courses.courses.find((c) => {
    return c.id === state.courses.courseId;
  });

  if (!course) {
    course = state.courses.courses_history.find((c) => {
      return c.id === state.courses.courseId;
    });
  }

  let linked_certificate = null;
  let cert_header = "";
  let cert_footer = "";

  if (
    state.certificate &&
    state.certificate.certificate &&
    course &&
    course.certificate &&
    course.certificate.id
  ) {
    linked_certificate = state.certificate.certificate.find(
      (s) => s.id === course.certificate.id
    );
  }

  if (linked_certificate) {
    cert_header = linked_certificate.header ? linked_certificate.header : "";
    cert_footer = linked_certificate.footer ? linked_certificate.footer : "";
  } else {
    const setting = state.setting.setting;

    if (setting && setting.certificate_header) {
      cert_header = setting.certificate_header;
    }
    if (setting && setting.certificate_footer) {
      cert_footer = setting.certificate_footer;
    }
  }

  return {
    setting: state.setting.setting,
    profile: state.profile.profile,
    action: props.history.action,
    students: state.student.students,
    course: course,
    cert_header: cert_header,
    cert_footer: cert_footer,
    isAttendanceActive: state.attendance.isActive,
  };
};

// ------------------------------

const mapDispatchToProps = (dispatch) => {
  return {
    fetchStudents: (courseId) =>
      dispatch(studentActions.fetchStudents(courseId)),
    updateLastViewed: (courseId) =>
      dispatch(studentActions.updateLastViewed(courseId)),
    setChargeId: (chargeId, courseId) =>
      dispatch(studentOrderActions.setChargeId(chargeId, courseId)),
    emailCertificate: (courseId, studentIds) =>
      dispatch(studentActions.emailCertificate(courseId, studentIds)),
    downloadCertificate: (courseId, studentId) =>
      dispatch(studentActions.downloadCertificate(courseId, studentId)),
    sendProviderNotification: (courseId, message) =>
      dispatch(studentActions.sendProviderNotification(courseId, message)),
    resendSurvey: (courseId, studenId) =>
      dispatch(studentActions.resendSurvey(courseId, studenId)),
    activateAttendance: (isActive, courseId) =>
      dispatch(attendanceActions.activateAttendance(isActive, courseId)),
    fetchAttendanceActiveFlag: (courseId) =>
      dispatch(attendanceActions.fetchAttendanceActiveFlag(courseId)),
    updateSettingColumns: (arrColumns) =>
      dispatch(settingActions.updateSettingColumns(arrColumns)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentsScreen);
