const favCat = {
  pick_clinic_mgmt: "Gestion Clinique",
  pick_endo: "Endodontie",
  pick_hygiene: "Hygiène",
  pick_pathology: "Pathologie",
  pick_implant: "Implant",
  pick_pedo: "Pédodontie",
  pick_perio: "Parodontie",
  pick_pharma: "Pharmacologie",
  pick_ortho: "Orthodontie",
  pick_resto: "Réparation / Prosthodontie",
  pick_surgery: "Chirurgie",
  pick_tmj: "ATM",
};

const FR_TRANSLATION = {
  sign_up: "Créer compte",
  login: "Se connecter",
  courses: "Formations",
  short_courses: "FC",
  language: "English",
  pick_french: "En Français",
  pick_english: "En Anglais",
  short_language: "En",

  g_unsaved_changes: "Vos modifications n'ont pas été enregistrées. Êtes-vous sûr de vouloir quitter?",
  g_bar_learn: "Apprentissage",
  g_bar_search: "Recherche",
  g_search_by: "Titre du cours, catégorie, etc.",
  g_bar_my_courses: "Mes Cours",
  g_bar_my_account: "Compte",
  g_bar_purhase_history: "Historique d'achat",

  g_delete: "Supprimer",
  g_yes: "Oui",
  g_no: "Non",
  g_hi: "Salut",
  g_review: "Critique",
  g_reviews: "Critiques",
  g_enter_an_address: "Tapez votre adresse...",

  g_load_more: "Charger plus",

  // ------------------------------
  g_custom_date: "Date personnalisée",
  g_all_time: "Tout",
  g_all: "Tout",
  g_ok: "Ok",
  g_password: "Mot de passe",
  g_email: "Courriel",
  g_error_occured: "Une erreur est survenue",
  g_phone_number: "Téléphone",
  g_license_number: "No. License",
  g_cancel: "Annuler",
  g_close: "Fermer",
  g_next: "Suivant",
  g_done: "Terminé",
  g_edit: "Éditer",
  g_reset: "Réinitialiser",
  g_clear: "Effacer",
  g_save: "Enregistrer",
  g_back: "Retour",
  g_sort: "Trier",
  g_undo: "Annuler",
  g_memberships: "Abonnements",

  g_registered: "Inscrit",
  g_unregistered: "Déinscrit",
  g_staff_registered: "Personnel inscrit",
  g_remove_photo: "Supprimer Photo",
  g_remove_cert: "Supprimer certificat",
  g_remove_receipt: "Supprimer Reçu",
  g_delete: "Supprimer",
  g_decline: "Déclin",
  g_accept: "J'accepte",
  g_send: "Envoyer",
  g_view: "Voir",
  g_occupation: "Occupation",
  g_in_dentistry_since: "En dentisterie depuis",
  g_graduation_date: "Année de graduation",
  g_licence_odq: "Votre ordre est l'ODQ?",
  g_please_enter_valid: "Veuillez saisir ",
  g_passcode: "Mots de passe",
  g_name: "Prénom",
  g_first_name: "Prénom",
  g_last_name: "Nom",
  g_gender: "Genre",
  g_dob: "Date de naissance",
  g_search_address: "Chercher votre adresse... ",
  g_street_number: "No civique",
  g_street_name: "Nom de rue",
  g_country: "Pays",
  g_state: "État / Province",
  g_city: "Ville",
  g_zip: "Code Postale",
  g_address: "Adresse",
  g_congrats: "Félicitation",
  g_account_created:
    "Votre compte a été créé. Veuillez l'activer via le courriel de vérification du compte avant de vous connecter.",
  g_manage_staff: "Mon équipe",
  g_manage_ce: "Mes crédits",
  g_expense: "Gestion dépenses",
  g_payment: "Paiement",
  g_payment_signin_blurb: "Connectez-vous pour gérer vos modes de paiement",
  g_order_history: "Mes commandes",
  g_settings: "Réglages",
  g_logout: "Se déconnecter",
  g_confirmation_required: "Confirmation requise",
  g_are_you_sure_delete: "Êtes-vous sûr de vouloir supprimer?",
  g_view_all: "Voir plus  ",
  g_completed: "Cumulé",
  g_pending: "À venir",
  g_view_details: "Détails  ",
  g_credits: "Crédits",
  g_credits_signin: "Gérer vos crédits",
  g_country_a_z: "Pays A-Z",
  g_providers_a_z: "Fournisseur A-Z",
  g_specialist_a_z: "Specialiste A-Z",
  g_professor_by_last_name: "Professeur par nom",
  g_results: "Résultats",
  g_result: "Résultats",
  g_hide_details: "Cacher les détails",
  g_show_details: "Afficher les détails",
  g_view_in: "Afficher en ",
  g_from: "Du: ",
  g_to: "    au: ",
  g_from_plain: "Du",
  g_to_plain: "Au",
  g_select_from_date: "Date de début  ",
  g_select_to_date: "Date de fin  ",

  g_search_city_placeholder: "Rechercher une ville...  (New York)",
  g_date: "Date",
  g_setting: "Type de formation",
  g_component: "Cours Théorique / pratique",
  g_audience: "Audience",
  g_price: "Prix",
  g_unlimited: "Illimitée",
  g_under: "Moins que ",
  g_filters: "Filtrer",
  g_ascending: "plus tôt - plus tard",
  g_descending: "plus tard - plus tôt",
  g_low_high: "min - max",
  g_high_low: "max - min",
  g_camera: "Caméra",
  g_photo_gallery: "Galerie de photos",

  g_for: " en ",
  g_in: " en ",
  g_no_credits: "Aucun",
  g_suite: "No appartement",
  g_search_on_google: "Rechercher votre adresse",
  g_invalid_address_title: "Adresse invalide",
  g_invalid_address:
    "Cette adresse n'est pas valide. Utilisez la recherche d'adresse pour vous aider.",
  g_confirmation_leave_desc:
    "Le lien que vous avez sélectionné s'ouvrira dans une nouvelle fenêtre. Voulez-vous continuer?",

  g_referral_code: "Code de parrainage",
  g_referral: "Prog. de parrainage",
  g_my_progress: "Mes tâches",
  g_group_progress: "Statistiques du groupe",
  g_challenge_expires: "Le défi expire",
  g_referral_code_error:
    "Le code de parrainage n'est pas valide, veuillez le vérifier et réessayer.",
  g_challenge_complete: "Félicitations, vous avez terminé le défi !!!",

  g_referral_code_optional: "optionnel",
  g_change_profile_photo: "Changer votre photo de profil",
  g_contact_us: "Nous contacter",
  g_or: "ou",
  g_new: "Ajouter",
  g_add_ce: "Ajouter crédits",

  g_add: "Ajouter",
  g_view_edit: "Ouvrir / Éditer",

  g_edit_ce: "Éditer vos crédits",
  g_search_filters: "Filtres de recherche",
  g_category: "Catégorie",
  g_provider: "Fournisseur",
  g_address_search: "Recherche d'adresse",
  // ------------------------------

  g_manage_title: "Gestion",
  g_add_staff: "Ajouter un membre de l'équipe",
  g_edit_staff: "Éditer un membre de l'équipe",

  g_home: "Accueil",
  g_about_us: "À  propos",
  g_course_provider_signup: "Compte pour fournisseur de cours",
  g_export: "Exporter",
  g_this_may_take_a_few_minutes: "Traitement en cours, cela peut prendre quelques minutes",

  g_my_certificates: "Mes certificats",
  g_my_staff_certificates: "Crtificats de mon équipe",

  // ------------------------------

  home_recently_viewed: "Consulté récemment",
  home_recent_registrations: "Vos inscriptions récentes",
  home_recommended: "Recommandé pour vous",
  home_trending: "Tendance",
  home_newly_added: "Nouvautés",
  home_by_provider: "Cours par fournisseur",

  // ------------------------------

  order_desc_part_1:
    "Vous consultez actuellement les prix dans votre devise préférée",
  order_desc_part_2:
    ". Si cette devise diffère de celle de votre fournisseur de cours, ZubU applique un taux de change indicatif au prix de base pour votre confort visuel. Pour afficher les prix exacts des commandes, sélectionnez 'Afficher en'.",
  order_email_sent: "Reçu par courriel envoyé",
  order_email_sent_message:
    "Votre reçu de commande vous a été envoyé par courriel avec succès. Veuillez vérifier que ZubU figure sur votre liste de destinataires acceptés.",

  order_email_receipt: "Reçu par courriel",
  order_number: "No commande",
  order_blurb: "Aucune commande n'existe.",
  order_blurb_sigin: "Historique de vos commandes",
  order_balance_remaining: "Solde restant:",

  pick_success: "payé",
  pick_failed: "erreur",
  pick_pending: "à venir",

  // ------------------------------


  staff_save_disclaimer:
    "Veuillez vérifier que les informations saisies sont correctes. Cela apparaîtra sur le certificat d'achèvement du membre inscrit.",

  staff_empty_title: "Inscrivez toute l'équipe en quelques clics",
  staff_empty_blurb:
    "Ajoutez ici les membres de votre personnel. Cela vous permettra de les inscrire à des cours en leur nom. Chaque membre recevra sa confirmation d'inscription, les liens d'accès aux cours, leur sondage et leur certificat de réussite.",
  staff_add_member: "Ajouter",
  staff_edit_member: "Éditer",
  staff_delete_confirm:
    "Êtes-vous sûr de vouloir supprimer ce membre de votre équipe ?",

  // ------------------------------
  ce_empty_title: "Gérez et suivez vos crédits CE",
  ce_empty_blurb: "Restez organisé avec vos crédits CE pour vos cours passés et à venir. Exportez un résumé CE vers un fichier excel ou pdf avec toutes vos attestations de réussite.",
  ce_new_entry: "Nouvelle entrée",
  ce_completed: "Cumulé",
  ce_pending: "À venir",
  ce_filter_date: "Filtrer par date",

  ce_export_to_cvs_title: "Exporter le résumé du rapport CE vers un fichier CSV?",
  ce_export_to_cvs_include_cert: "Inclure vos certificats d'achèvement?",
  ce_export_to_cvs_include_cert_blurb: "En cochant cette case, votre résumé CE sera exporté vers un fichier CSV. De plus, les certificats d'achèvement correspondants seront téléchargés dans un dossier .zip.",

  ce_title: "Titre",
  ce_credit_lecture_hours: "Crédit heures théoriques",
  ce_credit_handson_hours: "Crédit heures pratiques",
  ce_credit_odq: "Crédit ODQ (*si appliquable)",
  ce_course_provider: "Fournisseur",
  ce_course_start_date: "Date du cours",
  ce_add_certificate: "Ajouter un certificat",
  ce_pinch: "* Pincer pour agrandir",
  ce_view_certificate: "Ouvrir certificat",
  ce_add: "Ajouter CE",
  ce_edit: "Éditer CE",
  ce_currency: "Devise",
  ce_saved: "Enregistré avec succès",
  ce_downloading_certificates: "Téléchargement du résumé CE. Cela peut prendre quelques minutes.",

  terms_lecture: "Théorie",
  terms_handson: "Pratique",

  welcome_back: "Bienvenue ",
  welcome_for_dental_professionals: "Pour les professionnels dentaires",
  welcome_for_dental_desc_1:
    "Rejoignez une communauté de dentistes et d'hygiénistes. Téléchargez l'application ZubU et commencez à vous inscrire à la formation continue en quelques minutes.",
  welcome_for_dental_desc_2:
    "Découvrez l'avenir de la gestion de formation dentaire dans une application mobile pratique.",

  welcome_for_provider: "Pour les fournisseurs de cours",
  welcome_for_provider_desc_1:
    "Les professionnels dentaires cherchent à s'inscrire à des cours comme le vôtre. Mettez vos cours là où ils peuvent les voir.",
  welcome_for_provider_desc_2:
    "Aidez des milliers de dentistes à accéder au monde de la formation dentaire à portée de main.",

  welcome_learn_about_the_app: "En savoir plus sur l'application",
  welcome_learn_about_the_app_sub:
    "Bienvenue dans le futur de la formation continue",
  welcome_learn_about_the_app_text:
    "Centralisation de la recherche, de l'inscription et du suivi des crédits dans une seule application pratique",

  welcome_who_can_sub: "Qui peut utiliser",
  welcome_who_can_text:
    "ZubU a été créé pour les professionnels dentaires qui ont besoin d'une formation continue pour conserver leur licence. Cela comprend les dentistes généralistes, les spécialistes et les hygiénistes.",
  welcome_market_place_sub: "Marché de FC dentaire",
  welcome_market_place_text:
    "Consultez les cours de FC dentaire par une multitude de fournisseurs proches et lointains. Recherchez par lieu, langue, catégorie et plus encore.",
  welcome_register_directly_sub: "Inscrivez-vous directement sur l'application",
  welcome_register_directly_text:
    "Trouvez le cours de vos rêves, inscrivez-vous et payez en quelques clics. Dites adieu au fastidieux processus d'inscription.",
  welcome_ce_track_sub: "Gérez vos crédits accumulés",
  welcome_ce_track_text:
    "Le calculateur de crédit est tout ce dont vous avez besoin pour surveiller vos crédits passés et a venir. Du suivi automatique aux options de saisie manuelle, laissez l'application ZubU vous aider à gérer vos crédits.",
  welcome_discover_sub: "Découvrez de nouveaux cours intéressants",
  welcome_discover_text:
    "Vous retournez toujours chez les mêmes fournisseurs ? Découvrez ce qu'il vous manque en cherchant dans toute la communauté de formation continue dentaire!",
  welcome_and_more_sub: "Et bien plus",
  welcome_and_more_text:
    "Découvrez toutes les fonctionnalités pour vous aider à exceller dans le domaine dentaire. ZubU connecte des milliers de dentistes à de la formation de qualité.",
  welcome_discover_the_features:
    "Découvrez les fonctionnalités de l'application",
  welcome_discover_the_features_sub:
    "Rejoignez une communauté de dentistes et d'hygiénistes. Téléchargez l'application ZubU et commencez à vous inscrire à la formation continue en quelques minutes.",
  welcome_for_providers_sub_1:
    "En centralisant le formation dentaire, nous créons une communauté de prestataires du monde entier qui bénéficient d'une exposition à leurs cours comme jamais auparavant.",
  welcome_for_providers_sub_2:
    "Une application, un emplacement, des milliers d'utilisateurs.",
  welcome_for_providers_sub_3:
    "Inscrivez-vous aujourd'hui et mettez votre cours dans la poche de chaque dentiste.",
  welcome_benefits_to_provider_title:
    "Avantages pour les fournisseurs de cours.",
  welcome_benefits_to_provider_sub_title_1: "Atteignez des milliers",
  welcome_benefits_to_provider_sub_text_1:
    "Pour tous ceux qui fournissent de la formation dentaires de qualité. La plate-forme ZubU est là pour vous permettre d'étendre votre portée à des milliers de professionnels dentaires enthousiastes tels que des dentistes généralistes, des spécialistes et des hygiénistes.",
  welcome_benefits_to_provider_sub_title_2: "Augmenter la visibilité",
  welcome_benefits_to_provider_sub_text_2:
    "Présentez votre cours et créez des publicités qui généreront immédiatement du trafic vers votre fiche. Utilisez nos outils de publicité ciblée pour promouvoir vos cours auprès des personnes intéressées à s'inscrire.",
  welcome_benefits_to_provider_sub_title_3: "Gérez vos cours",
  welcome_benefits_to_provider_sub_text_3:
    "Suivez l'évolution de votre cours grâce à l'accès à des statistiques et à des données démographiques en temps réel, enregistrez les présences, envoyez des e-mails et proposez des codes promotionnels. Ce ne sont que quelques-unes des fonctionnalités fournies par ZubU.",
  welcome_benefits_to_provider_sub_title_4: "Concentrez-vous sur vos cours",
  welcome_benefits_to_provider_sub_text_4:
    "Concentrez-vous sur la création de cours de qualité et laissez ZubU faire le gros du travail en gérant vos inscriptions, votre facturation et le traitement sécurisé des paiements.",
  welcome_flexible_custom: "Flexible et personnalisable",
  welcome_flexible_custom_text:
    "Gérez les inscriptions et les paiements avec notre plateforme facile à utiliser.",

  welcome_what_included_title: "Les avantages",
  welcome_what_included_sub_1: "Aucun risque",
  welcome_what_included_text_1:
    "Modèle simple de paiement à l'utilisation. Pas de frais cachés, pas de frais d'inscription ou d'abonnement. Tarification facile et transparente.",
  welcome_what_included_sub_2: "Sécurité maximale",
  welcome_what_included_text_2:
    "Les paiements et les transactions sont alimentés par Stripe avec une conformité PCI niveau 1. Les fonds sont directement transférés sur votre compte.",
  welcome_what_included_sub_3: "Facile à utiliser",
  welcome_what_included_text_3:
    "Publiez vos annonces de cours en quelques clics. Créez des options d'inscription personnalisées pour plusieurs audiences. Gérer les inscriptions et les remboursements.",
  welcome_what_included_sub_4: "Outils publicitaires ciblés",
  welcome_what_included_text_4:
    "Utilisez nos outils de publicité ciblée pour promouvoir vos cours auprès des personnes les plus intéressées à les suivre.",
  welcome_what_included_sub_5: "Quiz et Certificats",
  welcome_what_included_text_5:
    "Créez des quiz et des certificats d'achèvement sans frais supplémentaires.",
  welcome_what_included_sub_6: "Statistiques de cours",
  welcome_what_included_text_6:
    "Surveillez les données démographiques de vos inscrits. Utilisez ces informations pour vous aider à planifier les cours futurs et à optimiser votre réussite.",
  welcome_what_included_sub_7: "Biographie des enseignants",
  welcome_what_included_text_7:
    "Créez une liste de vos conférenciers fréquents et leurs biographies pour les ajouter facilement à vos cours dans le créateur de cours.",
  welcome_what_included_sub_8: "Créer des brouillons",
  welcome_what_included_text_8:
    "Enregistrez vos cours dans des brouillons pour une activation facile.",
  welcome_what_included_sub_9: "ÉLARGISSEZ VOTRE PORTÉE",
  welcome_what_included_text_9:
    "Rejoignez la révolution mobile. Découvrez le pouvoir de faciliter l'expérience utilisateur et de promouvoir sans effort des cours à l'échelle mondiale.",
  welcome_what_included_sub_10: "Paiement et Historique",
  welcome_what_included_text_10:
    "Restez au courant des paiements et de l'historique des transactions grâce à nos rapports de bilan détaillés.",
  welcome_what_included_sub_11: "Capacité personnalisable",
  welcome_what_included_text_11:
    "Augmentez ou diminuez la limite de capacité de votre cours. Cela vous permet de faire de la publicité sur d'autres plateformes et d'éviter le surbooking.",
  welcome_what_included_sub_12: "ÉVALUATIONS et Commentaires",
  welcome_what_included_text_12:
    "Affichez et répondez aux avis des inscrits qui contribuent à votre note d'évaluation totale.",

  welcome_ready_to_start: "Prêt à commencer?",
  welcome_create_account_or_demo: "Créer un compte ou demander une démo",
  welcome_ready_create_account: "Créer un compte",
  welcome_ready_request_demo: "Demander une démo",
  welcome_provider_index_title:
    "Centralisation de la formation continue dentaire",
  welcome_provider_index_sub:
    "Une plateforme pour tous les fournisseurs de cours dentaires partout dans le monde",
  welcome_provider_index_desc:
    "Découvrez l'avenir de la gestion du formation continue dentaire. Recherchez par catégorie, fournisseur, accréditation et plus encore depuis l'application mobile.",
  welcome_provider_index_looking_for_more: "Vous cherchez plus de cours?",
  welcome_provider_index_looking_download:
    "Téléchargez l'application et commencez à apprendre en quelques minutes",
  welcome_provider_index_looking_never_easier:
    "Le suivi et calcul de vos crédit dentaire n'as jamais été aussi simples.",

  // ------------------------------
  category_screen_what_is: "Qu'est-ce que ZubU?",
  category_screen_blurb:
    "Un marché mondial où les fournisseurs de cours dentaires s'unissent pour fournir une plate-forme pratique pour les professionnels dentaires pour découvrir," +
    " s'inscrire et gérer leur crédit de formation continue.  Nous sommes également une communauté qui plaide pour un changement dans l'industrie et avons comme mission" +
    " de faciliter l'accès à de la formation continue chez les dentistes et hygiéniste dentaire." +
    " Rejoignez-nous aujourd'hui et faites partie du mouvement vers une formation continue centralisée.",

  category_screen_the_marketplace:
    "Le marché pour la formation continue dentaire",

  sign_up_start_learning_title: "Formation continue dentaire",
  sign_up_are_you_dental: "Êtes-vous un professionnel dentaire?",
  sign_up_as_dental:
    "Créez votre compte ZubU en tant que professionnel dentaire",
  sign_up_start_learning_today: "Commencez à apprendre aujourd'hui!",
  sign_up_are_you_provider: "Êtes-vous un fournisseur de cours?",
  sign_up_as_provider:
    "Créez votre compte ZubU en tant que fournisseur de cours",
  sign_up_start_posting_courses:
    "Publier vos formations sur ZubU auprès de milliers de professionnel dentaire.",

  // ------------------------------
  sign_in_stay_signed_in: "Rester connecté",
  sign_up_welcome: "Bienvenue chez ZubU",
  login_sign_in_required_blurb:
    "Connectez-vous à votre compte pour voir plus de cours, les offres spéciales, les commandes, vous inscrire à des formations et plus encore.",

  login_dont_have_account: "Pas de compte?",
  login_become_provider: "Devenir un fournisseur de cours?",
  login_join: "Créez un compte",

  sign_up_dentist_title: "Créez un compte (Professionnel dentaire)",
  sign_up_dentist_user_name: "Nom d'utilisateur",
  sign_up_dentist_disclaimer:
    "En cochant cette case, je confirme avoir lu et accepté d'être lié par nos",

  sign_up_intro:
    "Nous devons vous poser quelques questions. Cela vous permettra de vous inscrire à des cours sans avoir à remplir de formulaires d'inscription supplémentaires. Vous pouvez mettre à jour ces informations dans les paramètres.",
  sign_up_of: "de",
  sign_up_create_pass: "Mot de passe",
  sign_up_enter_pass: "Entrer votre mot de passe",
  sign_up_confirm_pass: "Confirmez votre mot de passe",
  sign_up_min_char_pass: "(Min. six caractêres)",
  sign_up_agreement: "Accord",
  sign_up_i_read: "J'ai lu et j'accepte les",
  sign_up_general_terms: "Conditions Générales",
  sign_up_supplier_terms: "Conditions générales du fournisseur",
  sign_up_and: "et",
  sign_up_privacy_policy: "Politique de confidentialité",
  sign_up_when_odq: "Lors de la sélection ODQ",
  sign_up_when_odq_desc:
    "en tant qu' ordre, les crédits seront calculés à l'aide du système de points ODQ pour les cours qui offrent des crédits ODQ. Si les crédits ODQ ne sont pas offerts, les crédits seront la somme des heures de cours théoriques et des heures pratiques sur une base 1: 1. ",
  sign_up_filter_courses_desc:
    "Montrez-moi des cours enseignés dans les langues suivantes:",
  sign_up_filter_courses_desc_2:
    "(Minimum une langue requise, ces préférences peuvent être mises à jour dans vos paramètres)",
  sign_up_filter_courses_desc_2:
    "(Minimum une langue requise, cela pré-filtrera les cours par langue dans la recherche. Désélectionnez si vous souhaitez ne jamais voir les cours enseignés dans ces langues)",
  sign_up_filter_courses_desc_4: "Minimum une langue de cours requise",
  sign_up_filter_4_disclaimer:
    "En poursuivant, j'accepte que ZubU ou ses représentants puissent me contacter par courriel, téléphone ou SMS (y compris par des systèmes de numérotation automatique) au adresse courriel ou numéro que je fournis, y compris à des fins de marketing.",
  sign_up_address_disclaimer:
    "Votre adresse professionnelle ou personnelle. Certains fournisseurs exigent une adresse postale pour expédier du matériel, des outils ou des certificats CE.",
  sign_up_thank_you: "Merci pour votre inscription!",
  sign_up_email_sent_to: "Nous avons envoyé un lien de vérification à:",
  sign_up_go_to_inbox: "Ouvrir vos courriels",
  sign_up_check_spam:
    "Vous n'avez pas reçu le courriel? Vérifiez votre dossier spam ou courrier indésirable, ou",
  sign_up_resend_email: "ré-envoyer le courriel.",
  sign_up_email_incorrect: "Adresse courriel ci-dessus incorrecte?",
  sign_up_try_again: "Réessayer",
  sign_up_different_email: "avec une adresse courriel différente.",
  sign_up_already_have_account: "Vous avez déjà un compte?",
  sign_up_email_sent: "Courriel envoyé",
  sign_up_password_not_match: "Les mots de passe ne correspondent pas",
  sign_up_creating_account: "Création de votre compte",

  // ------------------------------

  pick_others: "Autres",
  pick_dentist: "Dentiste",
  pick_general_dentist: "Dentiste Général",
  pick_endodontist: "Endodontiste",
  pick_oral_and_maxillofacial_surgeon: "Chirurgien buccal et maxillo-facial",
  pick_oral_radiologist: "Radiologiste",
  pick_orthodontist: "Orthodontiste",
  pick_oral_medicine_pathology: "Pathologie médecine buccale",
  pick_pediatric_dentist: "Dentiste pédiatrique",
  pick_periodontist: "Parodontiste",
  pick_prosthodontist: "Prosthodontiste",
  pick_dental_public_health: "Santé publique",
  pick_hygienist: "Hygiéniste",
  pick_specialist: "Spécialiste",

  pick_clinic_mgmt: favCat.pick_clinic_mgmt,
  pick_endo: favCat.pick_endo,
  pick_hygiene: "Hygiène",
  pick_pathology: "Pathologie",
  pick_implant: "Implant",
  pick_pedo: "Pédodontie",
  pick_perio: favCat.pick_perio,
  pick_pharma: favCat.pick_pharma,
  pick_ortho: favCat.pick_ortho,
  pick_resto: favCat.pick_resto,
  pick_surgery: "Chirurgie",
  pick_tmj: "ATM",

  pick_clinic_management: favCat.pick_clinic_mgmt,
  pick_endodontics: favCat.pick_endo,
  pick_pedodontics: favCat.pick_pedo,
  pick_periodontics: favCat.pick_perio,
  pick_pharmacology: favCat.pick_pharma,
  pick_orthodontics: favCat.pick_ortho,
  pick_restorative: favCat.pick_resto,
  pick_seminar: "Séminaire",
  pick_other: "Autre",

  cat_short_in_person: "En personne",
  cat_short_providers: "Fournisseur",
  cat_short_professors: "Professeur",
  cat_short_city: "Ville",
  cat_short_livestream: "En direct",
  cat_short_online: "Vidéo",
  cat_short_on_demand: "Vidéo",
  cat_short_clinic_mgmt: "Gest. Cliniq.",
  cat_short_endo: "Endo",
  cat_short_hygiene: "Hygiène",
  cat_short_pathology: "Pathologie",
  cat_short_implant: "Implant",
  cat_short_pedo: "Pedo",
  cat_short_perio: "Paro",
  cat_short_pharma: "Pharma",
  cat_short_ortho: "Ortho",
  cat_short_resto: "Resto",
  cat_short_seminar: "Séminaire",
  cat_short_other: "Autre",
  cat_short_specialist: "Spécialiste",
  cat_short_surgery: "Chirurgie",
  cat_short_tmj: "ATM",
  cat_short_last_minute: "Dern. Min.",
  cat_short_free: "Gratuit",

  pick_assistant: "Assistante",
  pick_receptionist: "Réceptionniste",
  pick_clinic_manager: "Directeur de clinique",
  pick_denturologist: "Denturist",
  pick_student: "Étudiant",
  pick_lab_technician: "Technicien laboratoire",

  // ------------------------------

  forgot_password_trouble: "Vous avez des problèmes pour vous connecter?",
  forgot_password_recover_pass: "Récupérez votre mot de passe avec",
  forgot_password_title: "Mot de passe oublié",
  forgot_password: "Mot de passe oublié?",
  forgot_password_send_reset: "Envoyer la réinitialisation",
  forgot_password_send_reset_description:
    "Entrez l'adresse courriel avec laquelle vous vous êtes enregistré et appuyez sur 'Envoyer la réinitialisation'",
  forgot_password_email_sent:
    "Courriel de réinitialisation du mot de passe envoyé",
  forgot_password_follow_instructions:
    "Veuillez suivre les instructions du courriel pour réinitialiser votre mot de passe.",
  forgot_password_please_enter_valid_email:
    "Veuillez saisir une adresse courriel valide.",

  setting_language: "Langue",

  pick_paid: "Avec prix",
  pick_free: "Gratuit",

  // ------------------------------

  mobile_orders_empty: "Vous n'avez pas d'historique d'achat",

  registration_evaluation: "Questionnaire",
  registration_title: "Mes inscriptions",
  registration_blurb: "Aucune inscription aux cours",
  registration_blurb_signin: "Consultez vos inscriptions",
  registration_skip: "Ignorer",
  registration_skip_review: "Ignorer l'avis",
  registration_skip_review_desc:
    "Votre avis aiderait vraiment votre fournisseur de cours. Êtes-vous sûr de vouloir ignorer?",
  registration_rate: "Évaluer",
  registration_instruction_title: "Comment accéder au contenu académique?",

  registration_instruction_title_provider:
    "Comment les étudiants accéderont-ils à votre contenu académique?",

  registration_instruction_desc:
    "Les instructions suivantes sont fournies par le fournisseur du cours.",
  registration_instruction_private_disclaimer:
    "Toute information saisie ici est privée et ne sera envoyée qu'aux étudiants inscrits lors de l'inscription.",
  registration_join_zoom_link:
    "Utilisez ce lien pour rejoindre l'événement en direct:",
  registration_no_zoom_link:
    "Le fournisseur de cours n'a pas encore publié le lien de l'événement en direct. Vous serez notifié lorsque le lien sera publié.",
  registration_no_instruction:
    "Veuillez vérifier vos courriels pour en trouver un intitulé 'Confirmation d'inscription'." +
    " Si vous n'avez pas reçu un tel courriel ou s'il ne contient pas d'instructions sur la façon d'accéder au contenu académique, veuillez contacter votre fournisseur de cours directement.",
  registration_set_alert: "Définir une alerte",
  registration_course_details: "Cours",
  registration_how_to_watch: "Accéder",
  registration_how_to_stream: "Accéder",
  registration_alert_on: "Alerte",
  registration_alert_off: "Alerte",
  registration_alert_starting: "Alerte: rappel de début de cours",
  registration_alert_select_error:
    "Les alertes ne peuvent pas être définies dans le passé, veuillez vérifier la date du cours et sélectionner à nouveau.",
  registration_archive_confirm: "Archiver ce cours",
  registration_archive_confirm_desc:
    "Enregistrez ce cours dans vos archives. Vous pouvez désarchiver à tout moment.",
  registration_no_courses_here: "Aucune inscription répertoriée",
  registration_no_archived_here: "Aucune inscription archivée",
  registration_show_archived: "Voir les inscriptions archivés",
  registration_show_courses: "Voir mes cours",
  registration_no_courses_here_long:
    "Aucune inscription. Cliquez sur rechercher pour trouver d'excellents cours de formation continue dentaire.",
  registration_no_archived_here_long:
    "Conservez vos cours passés dans vos archives pour une meilleure organisation.",
  registration_view_course: "Détails du cours",
  registration_tab_archived: "Archives",
  registration_archived: "Archivé",
  registration_unarchived: "Désarchivé",
  registration_unarchive: "Désarchivé",
  registration_tab_courses: "Mes Cours",
  registration_handout_title: "Documents",
  registration_handout: "Documents",
  registration_edit_handout_title: "Modifier vos documents",
  registration_handout_empty:
    "Documents partagés du fournisseur apparaîtront ici",
  registration_handout_info: "Documents fournis par votre fournisseur de cours",
  registration_handout_blurb_1: "Les documents enregistrés sont privés",
  registration_handout_blurb_2:
    "et partagée uniquement avec les étudiants inscrits à ce cours.",
  registration_handout_missing_file:
    "Nous ne trouvons pas le document, veuillez contacter le fournisseur du cours si le problème persiste.",

  registratin_no_handouts:
    "Aucun document disponible actuellement. Veuillez revenir plus tard.",

  registration_handout_drag:
    "Faites glisser et déposez des fichiers ici ou cliquez pour sélectionner des fichiers",
  registration_handout_drag_disclaimer:
    "(Seul *.pdf sera accepté, doit être inférieur à 10MB)",


  certificate_drag_disclaimer:
    "(Seul *.pdf, *.jpg, *.png sera accepté, doit être inférieur à 2MB)",



  registration_course_error: "Le cours n'existe plus ou a expiré",
  registration_course_share: "Partager",
  registration_course_location: "Emplacement",
  registration_course_watch: "Comment Accéder?",
  registration_course_stream: "Comment Accéder",

  rating_course_as_described: "Cours tel que décrit",
  rating_course_recommend: "Recommander à un ami",
  rating_course_leave_comment: "Laisser un commentaire",
  rating_course_leave_comment_placeholder:
    "Votre commentaire et note seront visibles sur la page détails du cours du fournisseurs.",

  rating_course_share_feedback: "Partagez vos commentaires",
  rating_course_leave_a_comment:
    "Vos commentaires sont importants pour nous, veuillez laisser un commentaire sur votre expérience en suivant cette formation.",

  share_modal_blurb:
    "Partagez le lien direct vers ce cours avec vos collègues ou amis. Cliquez pour copier le lien dans votre presse-papiers.",
  share_modal_copy: "Copier dans le presse-papier",
  share_modal_copied: "Copié",

  // ------------------------------

  certificate_supplied_blurb:
    "Certificat d'achèvement fournis par votre fournisseur de cours",
  certificate_error_not_yet_available:
    "Le certificat d'achèvement sera disponible à la fin du cours ou 48 heures suivant l'inscription d'un cours enregistré.",
  certificate_error_survey_not_completed:
    "Le certificat d'achèvement sera disponible après avoir rempli le questionnaire du cours. Le questionnaire sera disponible à la fin du cours ou 48 heures suivant l'inscription d'un cours enregistré.",
  certificate_error_no_certificate:
    "Le fournisseur du cours n'a pas ajouté de certificat d'achèvement pour ce cours. Pour plus d'informations, contactez le fournisseur du cours.",
  certificate_error_not_attended:
    "Vous n'avez pas reçu de certificat d'achèvement parce que vous n'avez pas été marqué comme présent le jour de l'événement par votre fournisseur de cours. S'il s'agit d'une erreur, veuillez contacter votre fournisseur de cours pour plus d'informations.",

  survey_thank_you: "Merci d'avoir répondu au questionnaire.",
  certificate_will_be_available:
    "Le certificat d'achèvement sera disponible sous peu.",

  // ------------------------------
  course_topic: "Code du sujet",
  course_reg_closed: "Inscription terminée",
  course_uni_associated: "Associé à une université",
  course_ref: "No Ref",
  course_cancellation_policy: "Politique d'annulation",

  course_contact_provider: "Contactez le fournisseur",
  course_view_bio: " (Voir Biographie)",
  course_hide_bio: " (Cacher Biographie)",
  course_tbd: "À déterminer",
  course_date_tbd: "Date: à déterminer",
  course_description: "Description",
  course_accreditation: "Accréditation",
  course_schedule: "Date du cours",
  course_reg_options: "Options d'inscription",
  course_objectives: "Objectifs du cours",
  course_location: "Lieu",
  course_notes: "Notes",
  course_sorry: "Désolé ... nous n'avons pas trouvé votre cours",
  course_sorry_desc:
    "Le cours que vous avez sélectionné a été supprimé par sont fournisseur ou a expiré.",

  course_info_request: "Demande d'information",
  course_info_request_msg:
    "Salut: J'aimerais des informations sur votre cours sur Zubu.",

  course_follow: "Suivez le fournisseur",
  course_follow_desc_1: "Ne manquez jamais un nouveau cours de",
  course_follow_desc_2:
    "Suivez le et soyez notifié lorsqu'ils publient un cours.",
  course_notification_alertme:
    "Alertez-moi lorsque ce fournisseur publie un nouveau cours.",
  course_membership_code: "Code de membre",
  course_membership_desc_1:
    "Les fournisseurs de cours tels que les clubs d'études dentaires peuvent offrir des abonnements. Si vous êtes membre de",
  course_membership_desc_2:
    "entrez votre code d'abonnement ici. Les membres peuvent bénéficier de tarifs réduits lors de l'inscription et l'accès à des cours supplémentaires auprès du fournisseur.",
  course_send_dm: "Message direct",

  provider_contact: "Contacter",
  provider_follow: "Favoris",
  provider_following: "Favoris",
  provider_you_follow: "Fournisseurs que vous suivez",
  provider_signed_up: "Membre",

  // ------------------------------

  settings_please_verify: "Veuillez vérifier les détails. Champs invalides.",
  settings_profile_saved: "Profil enregistré avec succès",
  settings_tab_registration: "Profil d'inscription",
  settings_tab_user: "Utilisateur",
  settings_reg_profile_blurb:
    "Pour vous inscrire aux cours sur ZubU, vous devez remplir votre profil. " +
    "Ce formulaire est enregistré à votre compte et rend le processus d'inscription rapide. " +
    "Les informations saisies ici sont privé et partagé uniquement avec les fournisseurs de cours qui en ont besoin à l'inscription de leurs cours.",

  settings_reg_profile_email_info: "Courriel associé à votre compte.",
  settings_reg_profile_enter_occ: "Taper votre occupation...",

  settings_address_title: "Adresse (bureau ou domicile)",

  settings_user_blurb:
    "Mettez à jour vos informations d'utilisateur. Ces informations sont publiques et peuvent être vu par d'autres membres de la communauté ZubU.",

  settings_user_blurb_tip:
    "Ce que les autres utilisateurs de ZubU voient lorsqu'ils vous recherchent sur l'application mobile ZubU.",

  settings_enter_a_password: "Entrer un mot de passe",
  // ------------------------------

  membership_blurb: "Vous n'avez actuellement aucun abonnement actif. Les adhésions sont un excellent moyen d'obtenir un accès premium à des cours sélectionnés, des offres et des prix réduits sur le marché ZubU. Contactez vos fournisseurs de cours pour en savoir plus.",
  membership_member_id: "No Membre",
  membership_member: "Membre",

  membership__modal_blurb1: "Avez-vous un abonnement avec ",
  membership__modal_blurb2: "? Si oui, veuillez confirmer votre numéro de membre pour sélectionner cette option d'inscription.",
  membership__modal_blurb3: "Votre numéro de membre sera enregistré dans 'Abonnements' et sera utilisé pour les inscriptions futures avec",

  payment_min_one_card: "Veuillez ajouter au moins une carte de crédit.",
  g_loading: "Téléchargement...",
  g_payment: "Paiement",
  g_confirmation_required: "Confirmation requise",
  g_add_payment_method: "Ajouter mode de paiement",
  g_save_card: "Enregistrer",
  g_name_on_card: "Nom sur la carte de crédit",
  g_applying_promo: "Code de membre: ",

  checkout_order_processed: "Commande traitée",
  checkout_order_message:
    "Un courriel de confirmation est envoyé avec les détails de la commande. Vous pouvez également afficher la commande à partir de l'historique de vos commandes",

  error_occurred: "Une erreur est survenue",
  error_default_message:
    "Une erreur est survenue. Veuillez réessayer plus tard.",
  error_default_fallback:
    "Nous travaillons à résoudre ce problème. Réessayez plus tard.",
  error_payment:
    "Mode de paiement non valide, veuillez vérifier vos informations et réessayer.",
  error_expired_registration_option:
    "L'une des options d'enregistrement sélectionnées a expiré. Veuillez sélectionner à nouveau.",
  error_payment_process_failed:
    "Le processus de paiement a échoué. Veuillez réessayer ultérieurement. Si le problème persiste, veuillez contacter le soutien technique.",
  error_duplicate_reg_option:
    "Maximum d'une option d'inscription par cours. Veuillez mettre à jour votre panier et réessayer, pour: ",
  error_already_registered:
    "Déjà enregistré. Veuillez mettre à jour votre panier et réessayer: ",
  error_invalid_promo_code:
    "Code promotionnel non valide. Le code promotionnel est expiré, invalide ou la limite d'utilisation est dépassée.",

  // -----
  error_capacity_limit: "Ce cours approche sa limite de capacité.",
  error_capacity_limit_2: " espace restant ",
  online_video: "Vidéo en ligne",
  livestream: "En Direct",
  classroom_lecture_only: "Classe théorique seulement",
  classroom_lecture_and_hands_on: "Classe théorique et pratique",

  cart_provided_by: "Fourni par",
  cart_sub_total: "Sous-total:",
  cart_coupon_discount: "Coupon Rabais:",
  cart_checkout_now: "Passer au paiement",
  cart_secure_checkout: "Paiement sécurisé",
  cart_applicable: "Applicable à",
  cart_applicable_you: " vous seulement ",
  cart_applicable_you_staff: " vous et le personnel ",
  cart_coupon: "Coupon",
  cart_enter_coupon: "Entrer coupon",
  cart_apply_coupon: "Appliquer Coupon",
  cart_no_credit_card:
    "Vous n'avez pas de carte de crédit enregistrée, veuillez en ajouter une.",
  cart_select_cc: "Sélectionner paiement",
  cart_update_cc: "Changer de paiement",

  cart_order_summary: "1. Sommaire de la commande",
  cart_payment_info: "3. Paiement",
  cart_payment_option: "4. Options de paiement",
  cart_payment_option_on: "le",
  cart_payment_option_pay_now: "payez maintenant",
  cart_payment_option_pay_multiple: "Paiements échelonnés",

  cart_payment_option_disclaimer:
    "(Les versements seront automatiquement facturés selon le mode de paiement enregistré sur l'application mobile aux dates indiquées ci-dessus." +
    " Les tentatives de paiement infructueuses peuvent entraîner la résiliation de votre inscription sans possibilité de remboursement. " +
    "Vous êtes responsable d'assurer que les paiements sont traités avec succès.)",

  cart_terms: "Conditions",
  cart_terms_desc:
    "En passant une commande, vous acceptez nos conditions générales d'utilisation et notre politique de confidentialité.",
  cart_billing: "Facturation",
  cart_billing_desc:
    "Lors de l'achat de cours auprès de plusieurs fournisseurs. ZubU répartira les frais par fournisseur. Vous recevrez un reçu par fournisseur de cours dans votre commande.",
  cart_exchange_rates: "Taux d'échange",
  cart_exchange_rates_you_are: "Vous consultez les prix en",
  cart_exchange_rates_you_are_desc:
    ". Les prix fixés par les fournisseurs résidant en dehors de votre pays sont affichés dans votre devise en utilisant un taux de change moyen. Les prix seront facturés dans la devise du pays du fournisseur de cours. Les taux de conversion sont déterminés par votre compagnie de carte de crédit au moment de l'achat. Consultez votre relevé de carte de crédit pour connaître le prix exact après l'achat.",

  cart_place_your_order: "Passer votre commande",
  cart_processing: "En traitement",

  cart_confirm_option: "Confirmer l'option d'inscription",
  cart_add_to: "Ajout. panier ",

  cart_select_options_staff: "Sélectionnez vos options",
  cart_already_in_cart: "Oops.. déjà dans votre panier",
  cart_already_in_cart_text: " est déjà dans votre panier pour ",
  cart_already_in_cart_text_try_again:
    ". Veuillez mettre à jour votre sélection ou votre panier et réessayer.",
  cart_thank_you: "Merci de magasiner",
  cart_added_to_cart: " a été ajouté à votre panier.",
  cart_payment_option: "options de paiement",

  // ------------------------------

  cart_title: "Panier",
  cart_blurb: "Votre panier est vide",
  cart_checkout_now: "Passer au paiement",

  // ------------------------------

  pricing_option_credits: "Crédits",
  pricing_option_lecture: "Théorique",
  pricing_option_handson: "Pratique",
  pricing_option_sold_out: "Vendu",
  pricing_option_for: "pour",

  pricing_option_pay: "Payer",
  pricing_option_today: "aujourd'hui",
  pricing_option_monthly: "mensualités",

  // ------------------------------

  course_details_register_now: "Ajout. au panier",
  course_details_message: "Message",
  course_details_message_blurb:
    "Envoyez un message directement au fournisseur de cours.",
  course_details_certificate_completion: "Certificat d'achèvement",
  course_certificate: "Certificat",

  course_student_ratings: "Score total",
  course_student_reviews: "Avis des étudiants",
  course_more_reviews: "Plus d'avis",

  g_out_of: "sur",

  // ------------------------------

  attendance_search_not_found:
    "Aucun résultat trouvé correspondant à vos critères",
  attendance_not_checked_in: "Non enregistré",
  attendance_checked_in: "Enregistré",
  attendance_check_in: "Présent",
  attendance_list_empty:
    "Votre liste de présence est vide. Aucun étudiant inscrit pour le cours.",
  attendance: "Présence",
  attendance_start_date: "Début",
  attendance_end_date: "Fin",
  attendance_license: "No. License",
  attendance_order: "",
  attendance_search_placeholder: "Nom, no. license, no. commande, etc.",
  attendance_passcode_saved: "Mot de passe enregistré",
  attendance_unavailable:
    "Uh-oh, la liste de présence que vous demandez n'est pas disponible actuellement. Veuillez réessayer plus tard ou contactez le fournisseur de cours pour plus d'informations.",
  attendance_enter_pass_name: "Entrez votre nom et votre mot de passe",
  attendance_reg_by: "Inscrit par",
  attendance_swipe: "Balayez vers la gauche",

  // ------------------------------

  course_search_not_found: "Aucun cours trouvé correspondant à vos critères",

  course_search_not_found2:
    "Vous n'avez trouvé aucun résultat ? Nos fournisseurs de cours travaillent à la création de nouveaux cours pour vous. Revenez bientôt.",

  // ------------------------------

  pick_signup: "Inscription à l'application",
  pick_first_registration: "Inscription de votre premier cours",
  pick_first_rate_review: "Premier avis donné pour votre cours",

  pick_male: "Homme",
  pick_female: "Femme",
  pick_other: "Autre",
  pick_yes: "Oui",
  pick_no: "Non",
  pick_english: "Anglais",
  pick_french: "Français",

  pick_livestream: "En direct",
  pick_classroom: "En salle",
  pick_online_video: "Vidéo en ligne",
  pick_lecture_only: "Cours théorique",
  pick_lecture_and_hands_on: "Cours théorique et pratique",
  pricing_option_for: "pour",
  pick_hygienist: "Hygiéniste",
  pick_specialist: "Spécialiste",
  pick_others: "Autres",
  pick_dentist: "Dentiste",

  // ------------------------------

  store_open: "Ouvrir sur le site ZubU?",
  store_powered_by_1: "Inscriptions aux cours de",
  store_powered_by_2: "sont alimentés par",

  store_to_register_action: "Pour vous inscrire à ce cours, vous devrez:",
  store_to_register_1: "Connectez-vous sur le site ZubU",
  store_to_register_2: "Cliquez sur le bouton ajouter au panier",
  store_to_register_3: "Suivez les instructions",

  sign_up_user_name_blurb:
    "Votre nom d'utilisateur protège votre identité. Il peut être vu par d'autres membres de la communauté ZubU dans le chat et autres espaces publics.",

  // ------------------------------

  error_occurred: "Une erreur est survenue",
  error_default_message:
    "Une erreur est survenue. Veuillez réessayer plus tard.",
  error_default_fallback:
    "Nous travaillons à résoudre ce problème. Réessayez plus tard.",
  error_permission_denied:
    "Accès refusé. Veuillez réessayer plus tard ou contactez votre administrateur ZubU.",
  error_email_exists:
    "Un compte existe déjà avec cette adresse courriel. Entrez une adresse courriel différente.",
  error_weak_password: "Le mot de passe doit comporter au moins 6 caractères",
  error_email_not_found: "Courriel et / ou mot de passe invalide.",
  error_invalid_email_password: "Courriel et / ou mot de passe invalide.",
  error_login_expired: "Connexion expirée",

  error_account_unverified:
    "Votre compte n'est pas vérifié. Vérifiez vos courriels pour un courriel intitulé Vérification du compte ZubU.",

  error_web_view_load:
    "Nous avons rencontré un problème lors de la navigation. Notre équipe est consciente de ce problème et travaille sur un correctif. Veuillez réessayer.",

  // ------------------------------

  validate_email_required: "courriel requis",
  validate_is_required: "champs requis",
  validate_min_length_two: "Minimum deux caractêres",
  validate_min_length_six: "Minimum de six characters",
  validate_min_length_ten: "Minimum de dix characters",
  validate_please_enter: "Valeur invalide pour",

  validate_phone_required: "Numéro de téléphone est requis",
  validate_min_length_phone: "Entrer un numéro de téléphone valide",

  // ------------------------------

  tips_expense: "Gérer vos dépenses",
  tips_expense_desc:
    "Gérer facilement vos dépenses. Téléchargez vos reçus. Exportez des résumés PDF ou Excel. Votre comptable vous aimera.",

  tips_start_tracking: "Gérer vos crédits",
  tips_start_tracking_desc:
    "Saviez-vous que vous pouviez saisir manuellement les informations de vos cours non inscrit par l'application?",

  tips_chat_share: "Discuter et partager",
  tips_chat_share_desc:
    "Ajoutez des collègues à votre liste d'amis et commencez à partager avec eux vos cours préférés.",

  tips_ce_summary: "Résumé de votre CE",
  tips_ce_summary_desc:
    "Avec ZubU, vous pouvez exporter votre résumé CE et vos certificats. Cliquez simplement sur l'icône de téléchargement et sélectionnez la plage de dates.",

  tips_register_team: "Inscrivez votre équipe",
  tips_register_team_desc:
    "Les cours de groupe sont excellents. Ajoutez votre personnel et inscrivez votre équipe en un clic.",

  tips_search: "Rechercher sur le marché",
  tips_search_desc:
    "Recherchez une multitude de fournisseurs dans une seule application.  Recherchez par emplacement, langue, catégorie et plus.",

  // ------------------------------

  // moment
  M_DATE_FORMAT_TINY: "DD MMM",
  M_DATE_FORMAT: "DD-MM-YYYY",
  M_TIME_FORMAT: "h:mm a",
  M_LONG_DATE_FORMAT: "dddd DD MMMM YYYY",
  M_SHORT_DATE_FORMAT: "DD MMM YYYY",
  M_CHAT_FORMAT: "D MMM YYYY",

  // fns
  FNS_DATE_FORMAT: "MM-dd-yyyy",
  FNS_LONG_DATE_FORMAT: "iiii MMMM do yyyy",
  FNS_TIME_FORMAT: "h:mm aa",

  // ------------------------------
};

export default FR_TRANSLATION;
