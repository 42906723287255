import React, { useEffect, useState } from "react";
import styles from "./MobileSetting.module.css";
import { useTranslation } from "react-i18next";
import InfoIcon from "@material-ui/icons/Info";
import { AsYouType } from "libphonenumber-js";
import * as lodashCloneDeep from "lodash.clonedeep";
import MatSelectOutline from "../../../components/ui/labelSelect/MatSelectOutline";
import AddressInput from "../../../components/ui/addressInput/AddressInput";
import MatInputOutline from "../../../components/ui/input/MatInputOutline";
import InlinePop from "../../../components/ui/popover/InlinePop";
import DateSelect from "../../../components/ui/dateSelect/DateSelect";
import { hasError } from "../../../globals/Validations";
import {
  buildSingleSelect,
  valueInCollection,
  translate,
} from "../../../globals/Common";
import { validateField } from "../../../globals/Validations";
import {
  selectSignupOccupation,
  OCCUPATION_OTHER,
} from "../../../globals/Data";
import {
  FNS_MEDIUM_DATE_FORMAT,
  incrementYearOfDate,
} from "../../../globals/Dates";

const buildYearPicker = () => {
  const picker = [];
  const thisYear = new Date().getFullYear();
  const maxYear = Number(thisYear) + 5;
  const minYear = Number(thisYear) - 60;

  for (let i = maxYear; i > minYear; i--) {
    picker.push({ value: i + "", label: i + "" });
  }
  return picker;
};

const buildGenderPicker = (t) => {
  const picker = [];
  picker.push({ value: "Male", label: t("pick_male") });
  picker.push({ value: "Female", label: t("pick_female") });
  picker.push({ value: "Other", label: t("pick_other") });
  return picker;
};

const buildYesNoPicker = (t) => {
  const picker = [];
  picker.push({ value: "Yes", label: t("g_yes") });
  picker.push({ value: "No", label: t("g_no") });
  return picker;
};

const YEARS_SELECT = buildYearPicker();

const PrivateProfileForm = (props) => {
  const { t } = useTranslation();

  const [transOcc, setTransOcc] = useState([]);

  // ------------------------------

  useEffect(() => {
    const translatedOccupation = [];

    for (const occ of selectSignupOccupation) {
      const copyOcc = { ...occ };
      copyOcc.label = translate(t, copyOcc.label);
      translatedOccupation.push(copyOcc);
    }

    translatedOccupation.sort((a, b) =>
      a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
    );

    setTransOcc(translatedOccupation);
  }, [t]);

  // ------------------------------

  const validate = async (field, validatorKey) => {
    if (field === null) {
      await props.updateHasErrors(props.formErrors);
    } else {
      const errors = validateField(
        props.profile,
        props.formErrors,
        field,
        validatorKey,
        t
      );

      await props.updateFormErrors(errors);
    }
  };

  // ------------------------------

  const inputChangeHandler = (e) => {
    const copyProfile = lodashCloneDeep(props.profile);

    if (e.target.name === "phone") {
      let value = e.target.value;
      if (value && !value.startsWith("+")) {
        value = "+" + value;
      }
      copyProfile[e.target.name] = new AsYouType().input(value).toString();
    } else {
      copyProfile[e.target.name] = e.target.value;
    }

    props.update(copyProfile);
  };

  // ------------------------------

  const selectChangeHandler = (key, e, select) => {
    const copyProfile = lodashCloneDeep(props.profile);

    copyProfile[key] = select
      ? key === "graduationDate"
        ? new Date(select.value, 0).toString()
        : select.value
      : "";

    props.update(copyProfile);
  };

  // ------------------------------

  return (
    <form className={styles.form}>
      {!props.hideHeaderInfo && (
        <div className={styles.pricingContainer}>
          <div className={styles.rowIt}>
            <div className={styles.icon}>
              <InfoIcon />
            </div>
            <div>{t("settings_reg_profile_blurb")}</div>
          </div>
        </div>
      )}
      <InlinePop help={t("settings_reg_profile_email_info")} />
      <MatInputOutline
        notopmargin="true"
        notopbottommargin="true"
        title={t("g_email")}
        name="email"
        value={props.profile.email}
        onChange={props.editEmail ? inputChangeHandler : () => { }}
        onBlur={
          props.editEmail ? validate.bind(this, "email", "email") : () => { }
        }
        error={props.editEmail ? hasError(props.formErrors.email) : false}
        helpertext={props.editEmail ? props.formErrors.email : ""}
        maxLength={200}
        readOnly={props.editEmail ? false : true}
      />
      <MatSelectOutline
        noTopMargin
        title={t("g_occupation")}
        placeholder={t("settings_reg_profile_enter_occ")}
        name="occupation"
        id="occupation"
        onChange={selectChangeHandler.bind(this, "occupation")}
        options={transOcc}
        value={
          props.profile.occupation
            ? {
              value: props.profile.occupation + "",
              label: translate(t, props.profile.occupation),
            }
            : null
        }
        getOptionLabel={(option) => option.label}
        getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
        onBlur={validate.bind(this, "occupation", "required")}
        error={hasError(props.formErrors.occupation)}
        helperText={props.formErrors.occupation}
        required={true}
      />
      <div style={{ height: "10px" }} />
      <MatSelectOutline
        noTopMargin
        title={
          valueInCollection(OCCUPATION_OTHER, props.profile.occupation)
            ? t("g_in_dentistry_since")
            : t("g_graduation_date")
        }
        placeholder=""
        name="graduationDate"
        id="graduationDate"
        onChange={selectChangeHandler.bind(this, "graduationDate")}
        options={YEARS_SELECT}
        value={
          props.profile.graduationDate
            ? buildSingleSelect(
              new Date(props.profile.graduationDate).getFullYear() + ""
            )
            : null
        }
        getOptionLabel={(option) => option.label}
        getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
        onBlur={validate.bind(this, "graduationDate", "required")}
        error={hasError(props.formErrors.graduationDate)}
        helperText={props.formErrors.graduationDate}
        required={true}
      />
      <div style={{ height: "10px" }} />
      <MatInputOutline
        notopbottommargin="true"
        title={t("g_license_number")}
        name="licenseNumber"
        value={props.profile.licenseNumber}
        onChange={inputChangeHandler}
        onBlur={validate.bind(this, "licenseNumber", "licenseNumber")}
        maxLength={1000}
        error={hasError(props.formErrors.licenseNumber)}
        helpertext={props.formErrors.licenseNumber}
        readOnly={false}
        required
      />
      <InlinePop
        help={t("sign_up_when_odq") + " " + t("sign_up_when_odq_desc")}
      />
      <MatSelectOutline
        noTopMargin
        title={t("g_licence_odq")}
        placeholder=""
        name="licencingBoard"
        id="licencingBoard"
        onChange={selectChangeHandler.bind(this, "licencingBoard")}
        options={buildYesNoPicker(t)}
        value={
          props.profile.licencingBoard
            ? {
              value: props.profile.licencingBoard + "",
              label: translate(t, props.profile.licencingBoard),
            }
            : null
        }
        getOptionLabel={(option) => option.label}
        getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
        onBlur={validate.bind(this, "licencingBoard", "required")}
        error={hasError(props.formErrors.licencingBoard)}
        helperText={props.formErrors.licencingBoard}
        required={true}
      />
      <MatSelectOutline
        noTopMargin
        title={t("g_licence_odq")}
        placeholder=""
        name="stateProvince"
        id="licencingBoard"
        onChange={selectChangeHandler.bind(this, "licencingBoard")}
        options={buildYesNoPicker(t)}
        value={
          props.profile.licencingBoard
            ? {
              value: props.profile.licencingBoard + "",
              label: translate(t, props.profile.licencingBoard),
            }
            : null
        }
        getOptionLabel={(option) => option.label}
        getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
        onBlur={validate.bind(this, "licencingBoard", "required")}
        error={hasError(props.formErrors.licencingBoard)}
        helperText={props.formErrors.licencingBoard}
        required={true}
      />
      {/** <div style={{ height: "10px" }} />
      <MatInputOutline
        notopmargin="true"
        title={t("g_phone_number")}
        name="phone"
        placeholder="+1 999 999 9999"
        value={props.profile.phone}
        onChange={inputChangeHandler}
        onBlur={validate.bind(this, "phone", "phone")}
        maxLength={16}
        error={hasError(props.formErrors.phone)}
        helpertext={props.formErrors.phone}
        readOnly={false}
        required
      /> */}
      <MatInputOutline
        notopmargin="true"
        title={t("g_first_name")}
        name="first"
        value={props.profile.first}
        onChange={inputChangeHandler}
        onBlur={validate.bind(this, "first", "plainRequired")}
        maxLength={1000}
        error={hasError(props.formErrors.first)}
        helpertext={props.formErrors.first}
        readOnly={false}
        required
      />
      {/**
      <MatSelectOutline
        noTopBottomMargin2
        title={t("g_gender")}
        placeholder="Gender"
        name="gender"
        id="gender"
        onChange={selectChangeHandler.bind(this, "gender")}
        options={buildGenderPicker(t)}
        value={
          props.profile.gender
            ? {
                value: props.profile.gender + "",
                label: translate(t, props.profile.gender),
              }
            : null
        }
        getOptionLabel={(option) => option.label}
        getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
        onBlur={validate.bind(this, "gender", "required")}
        error={hasError(props.formErrors.gender)}
        helperText={props.formErrors.gender}
        required={true}
      />
      <div className={styles.pricingContainer} style={{ paddingTop: "13px" }}>
        <DateSelect
          title={t("g_dob")}
          selected={
            props.profile.birthDate
              ? new Date(props.profile.birthDate)
              : incrementYearOfDate(new Date(), -18)
          }
          labelLong="true"
          dateFormat={FNS_MEDIUM_DATE_FORMAT}
          maxDate={incrementYearOfDate(new Date(), -18)}
          minDate={incrementYearOfDate(new Date(), -100)}
          required={true}
          onChange={(date) => {
            const copyProfile = lodashCloneDeep(props.profile);
            if (date) {
              date.setHours(0);
              date.setMinutes(0);
              date.setSeconds(0);
              copyProfile.birthDate = date;
            } else {
              copyProfile.expiration_date = null;
            }

            props.update(copyProfile);
          }}
        />
      </div>
      <div className={styles.sectionTitle}>{t("settings_address_title")}</div>
      <AddressInput
        value={[
          props.profile.streetNumber,
          props.profile.streetName,
          props.profile.country,
          props.profile.city,
          props.profile.stateProvince,
          props.profile.zipPostal,
        ]}
        suite={props.profile.suite}
        readOnly={false}
        onSelect={(selectAddress, geo) => {
          const copyProfile = lodashCloneDeep(props.profile);
          copyProfile.streetNumber = selectAddress[0];
          copyProfile.streetName = selectAddress[1];
          copyProfile.country = selectAddress[2];
          copyProfile.city = selectAddress[3];
          copyProfile.stateProvince = selectAddress[4];
          copyProfile.zipPostal = selectAddress[5];
          copyProfile.geoLat = geo.lat;
          copyProfile.geoLng = geo.lng;
          copyProfile.geoHash = geo.hash;
          props.update(copyProfile);
        }}
        onSuiteChange={(suite) => {
          const copyProfile = lodashCloneDeep(props.profile);
          copyProfile.suite = suite;
          props.update(copyProfile);
        }}
      />*/}
    </form>
  );
};

export default PrivateProfileForm;
