import React from "react";
import styles from "./DocumentListModal.module.css";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DescriptionIcon from "@material-ui/icons/Description";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CertDropzone from "../../ui/handout/CertDropzone";

const DocumentListModal = (props) => {
  const { t } = useTranslation();
  const fullScreen = false;
  const fullWidth = true;
  const maxWidth = "sm";

  const handleClose = () => {
    props.onClose();
  };

  // ------------------------------

  const openUri = async (uri) => {
    if (uri && uri.startsWith("https://firebasestorage.googleapis.com/")) {
      window.open(uri, "_blank");
    }
  };

  // ------------------------------

  const stripUri = (value) => {
    const indexOfFirst = value.indexOf("_");
    const newVal = value.substr(indexOfFirst + 1);
    const finalVal = newVal.split('?alt')[0];
    return finalVal.toLowerCase();
  }

  // ------------------------------

  const displayStaffCertificates = () => {
    const staffCertContent = [];

    if (props.registration.certificate_staff_uri && props.registration.certificate_staff_uri.length > 0) {

      staffCertContent.push(<div style={{ paddingBottom: "5px", paddingTop: "10px" }}>{t('g_my_staff_certificates')}:</div>)

      for (const staffCert of props.registration.certificate_staff_uri) {
        staffCertContent.push(
          <div
            className={styles.row}
            onClick={openUri.bind(this, staffCert)}
          >
            <DescriptionIcon color="primary" />
            &nbsp;
            <div className={styles.documentLink}>{stripUri(staffCert)}</div>
          </div>)
      }
    }

    return staffCertContent;
  }

  // ------------------------------

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={props.open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">
        {t("course_details_certificate_completion")}
      </DialogTitle>
      {!props.showDropzone && (<div className={styles.container}>
        <InfoOutlinedIcon />
        <div style={{ marginLeft: "10px" }}>
          {t("certificate_supplied_blurb")} <br />
          <b>{props.registration.course_title} </b>
        </div>
      </div>)}

      <DialogContent className={styles.dialogContent}>

        {props.registration && props.registration.certificate_uri && (
          <div style={{ paddingBottom: "5px" }}>{t('g_my_certificates')}:</div>
        )}

        {props.registration && props.registration.certificate_uri && (
          <div
            className={styles.row}
            onClick={openUri.bind(this, props.registration.certificate_uri)}
          >
            <DescriptionIcon color="primary" />
            &nbsp;
            <div className={styles.documentLink}>certificate.pdf</div>
          </div>
        )}

        {props.registration && props.registration.certificate_uri_fr && (
          <div
            className={styles.row}
            onClick={openUri.bind(this, props.registration.certificate_uri_fr)}
          >
            <DescriptionIcon color="primary" />
            &nbsp;
            <div className={styles.documentLink}>certificate_fr.pdf</div>
          </div>
        )}

        {props.registration && props.registration.certificate_staff_uri ? displayStaffCertificates() : null}

        {props.showDropzone && (<div className={styles.centerContainer}>
          <CertDropzone
            modal
            handout={props.mobileCerts}
            onChange={props.onDropzoneChange}
          />
        </div>)}

        {props.documentListModalError && (
          <div>
            <div
              style={{
                width: "100%",
                textAlign: "center",
                paddingBottom: "10px",
              }}
            >
              <LockOutlinedIcon style={{ fontSize: "55px", color: "gray" }} />
            </div>
            <div>{props.documentListModalError}</div>
          </div>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("g_ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentListModal;
