const favCat = {
  pick_clinic_mgmt: "Clinic Management",
  pick_endo: "Endodontics",
  pick_hygiene: "Hygiene",
  pick_pathology: "Pathology",
  pick_implant: "Implant",
  pick_pedo: "Pedodontics",
  pick_perio: "Periodontics",
  pick_pharma: "Pharmacology",
  pick_ortho: "Orthodontics",
  pick_resto: "Restorative / Prosthodontics",
  pick_surgery: "Surgery",
  pick_tmj: "TMJ",
};

const EN_TRANSLATION = {
  sign_up: "Sign up",
  login: "Sign in",
  courses: "Courses",
  short_courses: "CE",
  language: "Français",
  pick_french: "French",
  pick_english: "English",
  short_language: "Fr",

  g_unsaved_changes: "Your have unsaved changes. Are you sure you want to leave?",
  g_bar_learn: "Learn",
  g_bar_search: "Search",
  g_search_by: "Course title, category, etc.",
  g_bar_my_courses: "My Courses",
  g_bar_my_account: "Account",
  g_bar_purhase_history: "Purchase History",

  g_delete: "Delete",
  g_yes: "Yes",
  g_no: "No",
  g_hi: "Hi",
  g_loading: "Loading...",
  g_review: "Review",
  g_reviews: "Reviews",
  g_enter_an_address: "Enter an address...",

  g_load_more: "Load More",

  // ------------------------------
  g_custom_date: "Custom Date",
  g_all_time: "All Time",
  g_all: "All",
  g_ok: "Ok",
  g_password: "Password",
  g_email: "Email",
  g_error_occured: "Something went wrong",
  g_phone_number: "Phone Number",
  g_license_number: "License Number",
  g_cancel: "Cancel",
  g_close: "Close",
  g_next: "Next",
  g_edit: "Edit",
  g_reset: "Reset",
  g_clear: "Clear",
  g_done: "Done",
  g_back: "Back",
  g_save: "Save",
  g_sort: "Sort",
  g_undo: "Undo",
  g_memberships: "Memberships",

  g_registered: "Registered",
  g_unregistered: "Unregistered",
  g_staff_registered: "Staff Registered",
  g_delete: "Delete",
  g_remove_photo: "Remove Photo",
  g_remove_cert: "Remove Certificate",
  g_remove_receipt: "Remove Receipt",
  g_decline: "Decline",
  g_accept: "Accept",
  g_send: "Send",
  g_view: "View",
  g_occupation: "Occupation",
  g_in_dentistry_since: "In dentistry since",
  g_graduation_date: "Year of Graduation",
  g_licence_odq: "Is your licensing board ODQ?",
  g_please_enter_valid: "Please enter a valid ",
  g_name: "Name",
  g_passcode: "Passcode",
  g_first_name: "First Name",
  g_last_name: "Last Name",
  g_gender: "Gender",
  g_dob: "Date of Birth  (mm-dd-yyyy)",
  g_search_address: "Search for address... ",
  g_street_number: "Street No.",
  g_street_name: "Street Name",
  g_country: "Country",
  g_state: "State / Province",
  g_city: "City",
  g_zip: "ZIP / Postal Code",
  g_address: "Address",
  g_congrats: "Congratulations",
  g_account_created:
    "Your account has been created.  Please activate it via the account verificaton email before signing in.",
  g_manage_staff: "My Staff",
  g_manage_ce: "My Credits",
  g_expense: "Expense Tracker",
  g_payment: "Payment",
  g_payment_signin_blurb: "Sign in to manage your payment methods",
  g_order_history: "Order History",
  g_settings: "Settings",
  g_logout: "Logout",
  g_confirmation_required: "Confirmation Required",
  g_are_you_sure_delete: "Are you sure you wish to delete?",
  g_view_all: "View All  ",
  g_completed: "Completed",
  g_pending: "Pending",
  g_view_details: "View Details  ",
  g_credits: "Credits",
  g_credits_signin: "Manage your credits",
  g_country_a_z: "A-Z Country",
  g_providers_a_z: "Providers A-Z",
  g_specialist_a_z: "A-Z Specialist",
  g_professor_by_last_name: "Professor by Last Name",
  g_results: "Results",
  g_result: "Result",
  g_hide_details: "Hide Details",
  g_show_details: "Show Details",
  g_view_in: "View in ",
  g_from: "From: ",
  g_to: "    To: ",
  g_from_plain: "From",
  g_to_plain: "To",
  g_select_from_date: "Select From Date  ",
  g_select_to_date: "Select To Date  ",

  g_search_city_placeholder: "Search for a city...  (New York)",
  g_date: "Date",
  g_setting: "Setting",
  g_component: "Component",
  g_audience: "Audience",
  g_price: "Price",

  g_unlimited: "Unlimited",
  g_under: "Under ",
  g_filters: "Filters",
  g_ascending: "Ascending",
  g_descending: "Descending",
  g_low_high: "Low to High",
  g_high_low: "High to Low",
  g_camera: "Camera",
  g_photo_gallery: "Photo Gallery",
  g_for: " for ",
  g_in: " in ",
  g_no_credits: "No",
  g_suite: "Appartment #",
  g_search_on_google: "Search for address",
  g_invalid_address_title: "Invalid Address",
  g_invalid_address:
    "This address is invalid. Select the search for address option to assist you.",
  g_confirmation_leave_desc:
    "The link you selected will open in a new window.  Do you wish to proceed?",
  g_referral_code: "Referral Code",
  g_referral: "Referral Program",
  g_my_progress: "My Progress",
  g_group_progress: "Group Progress",
  g_challenge_expires: "Challenge Expires",
  g_referral_code_error:
    "The referral code is invalid, please verify it and try again.",
  g_challenge_complete: "Congratulations you completed the challenge !!!",

  g_referral_code_optional: "optional",
  g_change_profile_photo: "Change Profile Photo",
  g_contact_us: "Contact Us",
  g_or: "or",
  g_new: "New",
  g_add_ce: "Add Credits",

  g_add: "Add",
  g_view_edit: "View / Edit",

  g_edit_ce: "Edit Credits",
  g_search_filters: "Search Filters",
  g_category: "Category",
  g_provider: "Provider",
  g_address_search: "Address Search",
  // ------------------------------

  g_manage_title: "Manage",
  g_add_staff: "Add Staff Member",
  g_edit_staff: "Edit Staff Member",
  g_home: "Home",
  g_about_us: "About us",
  g_course_provider_signup: "Course provider sign up",
  g_export: "Export",
  g_this_may_take_a_few_minutes: "Processing, this may take a few minutes.",

  g_my_certificates: "My certificates",
  g_my_staff_certificates: "My staff certificates",

  // ------------------------------

  home_recently_viewed: "Recently Viewed",
  home_recent_registrations: "Your recent registrations",
  home_recommended: "Recommended for you",
  home_trending: "Trending",
  home_newly_added: "Newly added",
  home_by_provider: "Courses by provider",

  // ------------------------------

  order_desc_part_1: "You are currently viewing prices in your preferred",
  order_desc_part_2:
    " currency. If this currency differs from that of your course provider," +
    " ZubU applies an indicative exchange rate to the base price for your viewing comfort. " +
    "To view exact order prices select 'View in' when applicable.",
  order_email_sent: "Email Receipt Sent",
  order_email_sent_message:
    "Your order receipt was sent to you via email successfully.  Please verify that ZubU is on your accepted receiver list.",
  order_email_receipt: "Email Receipt",
  order_number: "Order #",
  order_blurb: "No orders have been created yet.",
  order_blurb_sigin: "View your order history",
  order_balance_remaining: "Balance Remaining:",

  pick_success: "paid",
  pick_failed: "failed",
  pick_pending: "pending",

  // ------------------------------

  staff_save_disclaimer:
    "Please verify that the information entered is correct.  This will appear on the registrants certificate of completion.",

  staff_empty_title: "Register the whole team in a few clicks",
  staff_empty_blurb:
    "Add your staff members here. This will allow you to register them for courses on their behalf. Each member will receive their registration confirmation, course access links, surveys and certificates to their own emails.",
  staff_add_member: "Add Staff Member",
  staff_edit_member: "Edit Staff Member",
  staff_delete_confirm: "Are you sure you wish to remove this staff member ?",

  // ------------------------------
  ce_empty_title: "Manage & Track your CE Credits",
  ce_empty_blurb: "Stay organized with your CE credits for your past and upcoming courses.  Export a CE summary to  an excel or pdf file with all your certificates of completion.",
  ce_new_entry: "New entry",
  ce_completed: "Completed",
  ce_pending: "Pending",
  ce_filter_date: "Filter by date",

  ce_export_to_cvs_title: "Export CE Report Summary to CSV file?",
  ce_export_to_cvs_include_cert: "Include your certificates of completion?",
  ce_export_to_cvs_include_cert_blurb: "By checking this box, your CE summary will be exported to a CSV file.  In addition, the corresponding certificates of completion will be saved to a .zip folder.",


  ce_title: "Title",
  ce_description: "Description",
  ce_credit_lecture_hours: "Credit Lecture Hours",
  ce_credit_handson_hours: "Credit Hands-on Hours",
  ce_credit_odq: "Credit ODQ (*If Applicable)",
  ce_course_provider: "Provider",
  ce_course_start_date: "Course Start Date  (mm-dd-yyyy)",
  ce_add_certificate: "Add certificate",
  ce_pinch: "* Pintch to zoom ",
  ce_view_certificate: "View certificate",
  ce_add: "Add CE",
  ce_edit: "Edit CE",
  ce_currency: "Currency",
  ce_saved: "CE saved successfully",
  ce_downloading_certificates: "Downloading CE summary. This may take a few minutes.",


  terms_lecture: "Lecture",
  terms_handson: "Hands-on",

  welcome_back: "Welcome back ",
  welcome_for_dental_professionals: "For Dental Professionals",
  welcome_for_dental_desc_1:
    "Join a community of dentists and hygienists. Download the ZubU app and start registering for continuing education in minutes.",
  welcome_for_dental_desc_2:
    "Discover the future of dental CE management. Search, registration and track CE in one convenient mobile app.",

  welcome_for_provider: "For Course Providers and Instructors",
  welcome_for_provider_desc_1:
    "Dental professionals are looking to register for courses just like yours. Put your courses where they can see them.",
  welcome_for_provider_desc_2:
    "Help thousands of dentist have access to the world of CE at their fingertips.",

  welcome_learn_about_the_app: "Learn About the App",
  welcome_learn_about_the_app_sub:
    "Welcome to the future of dental CE Management",
  welcome_learn_about_the_app_text:
    "Centralizing search, registration and credit tracking in one convenient app",
  welcome_who_can_sub: "Who Can Use",
  welcome_who_can_text:
    "ZubU was created for dental professionals who require continuing education to maintain their license. This includes, general dentists, specialists and hygienists.",
  welcome_market_place_sub: "Dental CE Marketplace",
  welcome_market_place_text:
    "View Dental CE courses by a multitude of providers near and far. Search by location, language, category and more.",
  welcome_register_directly_sub: "Register Directly on the App",
  welcome_register_directly_text:
    "Find your dream course, register and pay with just a few clicks. Say goodbye to the tedious registration process.",
  welcome_ce_track_sub: "We Track Your Credits",
  welcome_ce_track_text:
    "The credit tracker is all you need to monitor your past and pending credits. From automatic tracking to manual entry options, let the ZubU app help manage your CE.",
  welcome_discover_sub: "Discover New and Interesting Courses",
  welcome_discover_text:
    "Always going back to the same providers? Find out what you are missing by searching the whole CE community!",
  welcome_and_more_sub: "And So Much More",
  welcome_and_more_text:
    "Discover all the features to help you excel in the dental field. ZubU connects thousands of dentists to quality CE.",
  welcome_discover_the_features: "Discover the App's Features",
  welcome_discover_the_features_sub:
    "Join a community of dentists and hygienists. Download the ZubU app and start registering for continuing education in minutes.",
  welcome_for_providers_sub_1:
    "By centralizing dental CE, we create a community of providers from across the globe who gain exposure to their courses like never before.",
  welcome_for_providers_sub_2: "One app, one location, thousands of users.",
  welcome_for_providers_sub_3:
    "Join today and put your course in the pocket of every dentist.",

  welcome_benefits_to_provider_title: "Benefits to Course Providers.",
  welcome_benefits_to_provider_sub_title_1: "Reach Thousands",
  welcome_benefits_to_provider_sub_text_1:
    "For anyone who provides quality dental CE. The ZubU platform is here for you to expand your reach to thousands of eager dental professionals such as general dentists, specialists and hygienists.",
  welcome_benefits_to_provider_sub_title_2: "Increase Visibility",
  welcome_benefits_to_provider_sub_text_2:
    "Feature your course and build ads that will immediately drive traffic to your listing. Use our targeted advertising tools to promote your courses to those who are most likely to register.",
  welcome_benefits_to_provider_sub_title_3: "Manage your courses",
  welcome_benefits_to_provider_sub_text_3:
    "Keep track of how well your course is doing with access to real-time statistics and demographics, take attendance, send emails and offer promo codes. These are only of few of the features provided by ZubU.",
  welcome_benefits_to_provider_sub_title_4: "Do what you do best",
  welcome_benefits_to_provider_sub_text_4:
    "Focus on creating quality courses and let ZubU do the heavy lifting by managing your registrations, invoicing and secure payment processing.",
  welcome_flexible_custom: "Flexible and Customizable",
  welcome_flexible_custom_text:
    "Manage registrations and payments with our easy to use course provider platform.",

  welcome_what_included_title: "What's Included",
  welcome_what_included_sub_1: "No risk",
  welcome_what_included_text_1:
    "Easy pay-per-use model. No hidden fees, no sign up costs or subscriptions. Easy and transparent pricing.",
  welcome_what_included_sub_2: "Highest Security",
  welcome_what_included_text_2:
    "Payments and transactions are powered by Stripe with a PCI level 1 compliance. Funds are directly transfered to your account.",
  welcome_what_included_sub_3: "Easy to use",
  welcome_what_included_text_3:
    "Post your course listings in a few clicks. Create custom registration options for multiple audiences. Manage registrations and refunds.",
  welcome_what_included_sub_4: "Target Advertising Tools",
  welcome_what_included_text_4:
    "Use our targeted advertising tools to promote your courses to those who are most likely to take them.",
  welcome_what_included_sub_5: "Surveys & Certificates",
  welcome_what_included_text_5:
    "Create surveys and certificates of completion at no additional cost.",
  welcome_what_included_sub_6: "COURSE ANALYTICS",
  welcome_what_included_text_6:
    "Monitor the demographics of your registrants. Use this information to help plan future courses and optimize success.",
  welcome_what_included_sub_7: "SPEAKER BIOS",
  welcome_what_included_text_7:
    "Create a list of your frequent speakers and their biographies to easily add them to your courses in the course builder.",
  welcome_what_included_sub_8: "Save Templates",
  welcome_what_included_text_8:
    "Save course templates to drafts for easy activation.",
  welcome_what_included_sub_9: "EXPAND YOUR REACH",
  welcome_what_included_text_9:
    "Join the mobile revolution. Discover the power of facilitating user experience and effortlessly promote courses on a global scale.",
  welcome_what_included_sub_10: "Payment & Refund History",
  welcome_what_included_text_10:
    "Stay on top of payments and transaction history with our detailed balance sheet reporting.",
  welcome_what_included_sub_11: "Customizable Capacity",
  welcome_what_included_text_11:
    " Increase or decrease your course capacity limit. This allows you to advertise on other platforms and avoid overbooking.",
  welcome_what_included_sub_12: "RATINGS & Reviews",
  welcome_what_included_text_12:
    "View and reply to registrant reviews that contribute to your total rating score.",
  welcome_ready_to_start: "Ready to Get Started?",
  welcome_create_account_or_demo: "Create an account or request a demo",
  welcome_ready_create_account: "Create an account",
  welcome_ready_request_demo: "Request a demo",
  welcome_provider_index_title: "Centralizing Dental Continuing Education",
  welcome_provider_index_sub:
    "One platform for all course providers anywhere in the world",
  welcome_provider_index_desc:
    "Discover the future of dental CE management. Search, register and track your dental CE in one place. Search by category, provider, accreditation and more all from the mobile app.",
  welcome_provider_index_looking_for_more: "Looking for more courses?",
  welcome_provider_index_looking_download:
    "Download the app and start learning in minutes",
  welcome_provider_index_looking_never_easier:
    "CE tracking and registration has never been easier.",

  // ------------------------------
  category_screen_what_is: "What is ZubU?",
  category_screen_blurb:
    "A global online marketplace where dental course providers around the world can unite to provide one convenient platform for dental professionals to discover, " +
    "register and manage their CE. We are also a community advocating for a shift in the industry that will bring the courses to the dental professional in one convenient " +
    "location and eliminate the headache of endless searching, account creations and missed CE opportunities. Join ZubU today and be part of the movement towards centralized dental" +
    " continuing education.",

  category_screen_the_marketplace:
    "The Marketplace for Dental Continuing Education",

  sign_up_start_learning_title: "Start Learning or Teaching",
  sign_up_are_you_dental: "Are you a dental professional?",
  sign_up_as_dental: "Create your ZubU account as a dental professional",
  sign_up_start_learning_today: "Start learning today!",
  sign_up_are_you_provider: "Are you a course provider?",
  sign_up_as_provider: "Create your ZubU account as a course provider",
  sign_up_start_posting_courses:
    "Start posting your online education courses to thousands of students.",

  // ------------------------------

  sign_up_dentist_title: "Create Account (Dental Professional)",
  sign_up_dentist_user_name: "User Name",
  sign_up_dentist_disclaimer:
    "By checking this box I confirm that I have read and agree to be bound by our",

  // ------------------------------

  sign_in_stay_signed_in: "Stay signed in",
  sign_up_welcome: "Welcome to ZubU",
  login_sign_in_required_blurb:
    "Sign in to see more courses, view special offers, view order history, register and more.",

  login_dont_have_account: "Don't have an account?",
  login_become_provider: "Want to become a course provider?",
  login_join: "Join now",

  sign_up_intro:
    "We need to ask you a few questions.  This will allow you to register for courses without the need to fill out additional registration forms.  You can always update this information in your settings.",
  sign_up_of: "of",
  sign_up: "Sign up",
  sign_up_create_pass: "Create Password",
  sign_up_enter_pass: "Enter Password",
  sign_up_confirm_pass: "Confirm Password",
  sign_up_min_char_pass: "(Min. 6 characters)",
  sign_up_agreement: "Agreement",
  sign_up_i_read: "I have read and agree with the",
  sign_up_general_terms: "General Terms and Conditions",
  sign_up_supplier_terms: "Supplier Terms and Conditions",
  sign_up_and: "and",
  sign_up_privacy_policy: "Privacy Policy",
  sign_up_when_odq: "When selecting ODQ",
  sign_up_when_odq_desc:
    "as your licensing board, credits will be calculated using the ODQ point system for courses that offer ODQ credits. If ODQ credits are not offered, credits will be the sum of lecture hours and hands-on hours on a 1:1 basis.",
  sign_up_filter_courses_desc:
    "Show me courses taught in the following languages:",
  sign_up_filter_courses_desc_2:
    "(Minimum one required, these preferences can be updated in your settings)",
  sign_up_filter_courses_desc_3:
    "(Minimum one required, this will pre filter courses by language in the search.  Unselect if you wish never to see courses taught in these languages)",
  sign_up_filter_courses_desc_4: "Minimum one course language required",
  sign_up_filter_4_disclaimer:
    "By proceeding, I agree that ZubU or its representatives may contact me by email, phone, or SMS (including by automatic dialing systems) at the email address or number I provide, including for marketing purposes.",
  sign_up_address_disclaimer:
    "Your office or home address.  Some providers require a mailing address to ship materials, tools or CE certificates.",
  sign_up_thank_you: "Thanks for signing up!",
  sign_up_email_sent_to: "We've sent a verification link to:",
  sign_up_go_to_inbox: "Check your Email",
  sign_up_check_spam:
    "Didn't receive the email? Check your Spam or Junk folder, or",
  sign_up_resend_email: "resend email.",
  sign_up_email_incorrect: "Above email incorrect?",
  sign_up_try_again: "Try again",
  sign_up_different_email: "with a different email address.",
  sign_up_already_have_account: "Already have an account?",
  sign_up_email_sent: "Email Sent",
  sign_up_password_not_match: "Passwords do not match",
  sign_up_creating_account: "Creating your account",

  // ------------------------------

  pick_others: "Others",
  pick_dentist: "Dentist",
  pick_general_dentist: "General Dentist",
  pick_endodontist: "Endodontist",
  pick_oral_and_maxillofacial_surgeon: "Oral and Maxillofacial Surgeon",
  pick_oral_radiologist: "Oral Radiologist",
  pick_orthodontist: "Orthodontist",
  pick_oral_medicine_pathology: "Oral Medicine Pathology",
  pick_pediatric_dentist: "Pediatric Dentist",
  pick_periodontist: "Periodontist",
  pick_prosthodontist: "Prosthodontist",
  pick_dental_public_health: "Dental Public Health",
  pick_hygienist: "Hygienist",
  pick_specialist: "Specialist",

  pick_clinic_mgmt: favCat.pick_clinic_mgmt,
  pick_endo: favCat.pick_endo,
  pick_hygiene: "Hygiene",
  pick_pathology: "Pathology",
  pick_implant: "Implant",
  pick_pedo: "Pedodontics",
  pick_perio: favCat.pick_perio,
  pick_pharma: favCat.pick_pharma,
  pick_ortho: favCat.pick_ortho,
  pick_resto: favCat.pick_resto,
  pick_surgery: "Surgery",
  pick_tmj: "TMJ",

  pick_clinic_management: favCat.pick_clinic_mgmt,
  pick_endodontics: favCat.pick_endo,
  pick_pedodontics: favCat.pick_pedo,
  pick_pediatrics: favCat.pick_pedo,
  pick_periodontics: favCat.pick_perio,
  pick_pharmacology: favCat.pick_pharma,
  pick_orthodontics: favCat.pick_ortho,
  pick_restorative: favCat.pick_resto,
  pick_restorative_prosthodontics: favCat.pick_resto,
  pick_seminar: "Seminar",
  pick_other: "Other",

  cat_short_in_person: "In person",
  cat_short_providers: "Providers",
  cat_short_professors: "Professor",
  cat_short_city: "City",
  cat_short_livestream: "Livestream",
  cat_short_on_demand: "On demand",
  cat_short_online: "Online",
  cat_short_clinic_mgmt: "Clinic Mgmt",
  cat_short_endo: "Endo",
  cat_short_hygiene: "Hygiene",
  cat_short_pathology: "Pathology",
  cat_short_implant: "Implant",
  cat_short_pedo: "Pedo",
  cat_short_perio: "Perio",
  cat_short_pharma: "Pharma",
  cat_short_ortho: "Ortho",
  cat_short_resto: "Resto",
  cat_short_seminar: "Seminar",
  cat_short_other: "Other",
  cat_short_specialist: "Specialist",
  cat_short_surgery: "Surgery",
  cat_short_tmj: "TMJ",
  cat_short_last_minute: "Last Minute",
  cat_short_free: "Free",

  pick_assistant: "Assistant",
  pick_receptionist: "Receptionist",
  pick_clinic_manager: "Clinic Manager",
  pick_denturologist: "Denturologist",
  pick_student: "Student",
  pick_lab_technician: "Lab Technician",

  // ------------------------------

  forgot_password_trouble: "Having trouble logging in?",
  forgot_password_recover_pass: "Recover your password with",
  forgot_password_title: "Forgot Password",
  forgot_password: "Forgot your password?",
  forgot_password_send_reset: "Send Email Reset",
  forgot_password_send_reset_description:
    "Enter the email address you registered with and press 'Send Email Reset'",
  forgot_password_email_sent: "Password reset email sent",
  forgot_password_follow_instructions:
    "Please follow the instructions in the email to reset your password.",

  forgot_password_please_enter_valid_email: "Please enter a valid email.",

  setting_language: "Language",

  // ------------------------------

  mobile_orders_empty: "You have no purchase history",

  registration_evaluation: "Survey",
  registration_title: "My Registrations",
  registration_blurb: "You have no course registrations",
  registration_blurb_signin: "View your registrations",
  registration_skip: "Skip",
  registration_skip_review: "Skip Review",
  registration_skip_review_desc:
    "A review would really help your course provider.  Are you sure you wish to skip?",
  registration_rate: "Rate",
  registration_instruction_title: "How to access your academic content?",

  registration_instruction_title_provider:
    "How will students access your academic content?",

  registration_instruction_desc:
    "The following instructions have been supplied by your course provider on how to access the academic content.",

  registration_instruction_private_disclaimer:
    "Any information entered here is private and will only be sent to registered students upon registration.",

  registration_join_zoom_link: "Use this link to join the livestream event:",
  registration_no_zoom_link:
    "The course provider has not yet released the livestream event link.  You will be notified when it is released.",
  registration_no_instruction:
    "Please check your mailbox for an email titled 'Registration Confirmation'.  " +
    "If you did not receive it or if it does not contain instructions on how to access the academic content, please contact your course provider directly.",
  registration_set_alert: "Set an Alert",
  registration_course_details: "Course",
  registration_how_to_watch: "Watch",
  registration_how_to_stream: "Stream",
  registration_alert_on: "Alert on",
  registration_alert_off: "Alert",
  registration_alert_starting: "Alert: course start reminder",
  registration_alert_select_error:
    "Alerts cannot be set in the past, please verify the course date and select again.",
  registration_archive_confirm: "Archive Course",
  registration_archive_confirm_desc:
    "Save this course to your archives.  You can unarchive at anytime.",
  registration_no_courses_here: "No registrations listed",
  registration_no_archived_here: "No registrations archived",
  registration_no_courses_here_long:
    "No registrations. Click search to find great dental CE courses.",
  registration_no_archived_here_long:
    "Keep your past courses in your archive for better organization.",
  registration_unarchived: "Unarchived",
  registration_unarchive: "Unarchive",
  registration_archived: "Archived",
  registration_archive: "Archive",
  registration_view_course: "View Course",
  registration_show_archived: "View Archived",
  registration_show_courses: "View Courses",
  registration_tab_archived: "Archives",
  registration_tab_courses: "All Courses",
  registration_handout_title: "Course Handouts",
  registration_edit_handout_title: "Edit Course Handouts",
  registration_handout: "Handouts",
  registration_handout_empty: "Handouts will appear here when available",
  registration_handout_info: "Handouts supplied by your course provider",
  registration_handout_blurb_1: "Saved handouts are private",
  registration_handout_blurb_2:
    "and only shared with students registered to this course.",
  registration_handout_missing_file:
    "We are unable to find the document, please contact the course provider if the problem persists.",
  registration_course_error: "Course no longer exists or has expired",
  registration_course_share: "Share",

  registratin_no_handouts:
    "No handouts currently available. Please check back later.",

  registration_handout_drag:
    "Drag 'n' drop files here, or click to select files",
  registration_handout_drag_disclaimer:
    "(Only *.pdf will be accepted, must be less than 10MB in size)",


  certificate_drag_disclaimer:
    "(Only *.pdf, .jpg, .png will be accepted, must be less than 2MB in size)",

  registration_course_location: "Location",
  registration_course_watch: "How to Watch?",
  registration_course_stream: "How to Stream?",

  rating_course_as_described: "Course as described",
  rating_course_recommend: "Recommend to a friend",
  rating_course_leave_comment: "Leave a comment",
  rating_course_leave_comment_placeholder:
    "Your comment and rating will be visible on the providers course listing.",

  rating_course_share_feedback: "Share your feedback",
  rating_course_leave_a_comment:
    "Your feedback is imporant to us, please leave a comment on your experience taking this course.",

  share_modal_blurb:
    "Share the direct link to this course with your collegues or friends. Simply click to copy to your clipboard.",
  share_modal_copy: "Copy to clipboard",
  share_modal_copied: "Copied to clipboard",

  // ------------------------------

  certificate_supplied_blurb:
    "Certificate of completion supplied by your course provider",
  certificate_error_not_yet_available:
    "Certificate of completion will be available upon completion of the course or 48 hours after registration for on demand courses.",
  certificate_error_survey_not_completed:
    "Certificate of completion will be available after you complete the course survey.  Course survey will be available upon completion of the course or 48 hours after registration for on demand courses.",
  certificate_error_no_certificate:
    "The course provider has not added a certificate of completion for this course. For more information contact the course provider.",
  certificate_error_not_attended:
    "You did not receive a certificat of completion because you were not marked as present at the course by your provider.  If this is a mistake, please contact your course provider for more information.",

  survey_thank_you: "Thank you for completing the survey.",
  certificate_will_be_available:
    "Your certificate of completion will be available shortly.",

  // ------------------------------

  course_topic: "Subject Code",
  course_reg_closed: "Registration Closed",
  course_uni_associated: "University Associated",
  course_ref: "Ref #",
  course_cancellation_policy: "Cancellation Policy",

  course_contact_provider: "Contact the provider",
  course_view_bio: " (View Biography)",
  course_hide_bio: " (Hide Biography)",
  course_tbd: "To be determined",
  course_date_tbd: "Date: TBD",
  course_description: "Description",
  course_accreditation: "Accreditation",
  course_schedule: "Schedule",
  course_reg_options: "Registration Options",
  course_objectives: "Course Objectives",
  course_location: "Location",
  course_notes: "Notes",
  course_sorry: "Sorry ... we couldn't find your course",
  course_sorry_desc:
    "The course you selected has been deleted by its provider or has expired.",

  course_info_request: "Information Request",
  course_info_request_msg:
    "Hi: I would like information about your course posted on Zubu.",

  course_follow: "Follow the provider",
  course_follow_desc_1: "Never miss out on a new course from",
  course_follow_desc_2: "Follow them and be alerted when they post a course.",
  course_notification_alertme:
    "Alert me when this provider posts a new course.",
  course_membership_code: "Membership Code",
  course_membership_desc_1:
    "Course providers such as dental study clubs can offer memberships to their registrants. If you are a member of",
  course_membership_desc_2:
    "enter your membership code here. Members benefit from reduced pricing at registration and access to additional courses from the provider.",
  course_send_dm: "Send Direct Message",

  provider_contact: "Contact",
  provider_follow: "Follow",
  provider_following: "Following",
  provider_you_follow: "Providers you follow",
  provider_signed_up: "Joined",

  // ------------------------------

  settings_please_verify: "Please verify details.  Invalid fields.",
  settings_profile_saved: "Profile saved successfully",
  settings_tab_registration: "Registration / Profile",
  settings_tab_user: "User",
  settings_reg_profile_blurb:
    "To register for courses on ZubU you must fill out your registration" +
    " profile. This one-time form is saved to your account and makes the" +
    " registration process quick and easy. Information entered here is" +
    " private and only shared to course providers who require it at" +
    " registration.",

  settings_reg_profile_email_info: "Email associated to your account.",
  settings_reg_profile_enter_occ: "Enter occupation...",
  settings_address_title: "Address (office or home)",

  settings_user_blurb:
    "Update your user information. This information is public and can be seen by other members of the ZubU community.",

  settings_user_blurb_tip:
    "What other ZubU users see when searching for you on the ZubU mobile app.",

  settings_enter_a_password: "Enter a password",

  // ------------------------------

  membership_blurb: "You currently have no active memberships.  Memberships are a great way to gain premium access to selected courses, offers and reduced pricing on the ZubU marketplace. Contact your course providers to learn more.",
  membership_member_id: "Member ID",
  membership_member: "Member",
  membership__modal_blurb1: "Do you have a membership with ",
  membership__modal_blurb2: "? If yes, please confirm your membership identification number to select this registration option.",
  membership__modal_blurb3: "Your Member ID will be saved in 'Memberships' and will be used for future registrations with",


  payment_min_one_card: "Add a payment method.",
  g_payment: "Payment",
  g_confirmation_required: "Confirmation Required",
  g_add_payment_method: "Add Payment Method",
  g_save_card: "Save Card",
  g_name_on_card: "Name on Credit Card",
  g_applying_promo: "Member Code: ",

  checkout_order_processed: "Order Processed",
  checkout_order_message:
    "A confirmation email is sent with the order details.  You can also view the order from your order history.",

  error_occurred: "An error occurred",
  error_default_message: "Unknown error has occurred.  Please try again later.",
  error_default_fallback:
    "We are working on getting this fixed.  Try again later.",
  error_payment:
    "Invalid payment method, please review your details and try again.",
  error_expired_registration_option:
    "One of your selected registration options has expired.  Please select again.",
  error_payment_process_failed:
    "Payment Process Failed.  Please try again at a later time.  If the problem persists please contact support.",
  error_duplicate_reg_option:
    "Maximum of one registration option per course.  Please update your cart and try again, for: ",
  error_already_registered:
    "Already registered. Please update your cart and try again: ",
  error_invalid_promo_code:
    "Invalid promo code.  Promo code is expired, invalid or usage limit exceeded.",

  // -----

  error_capacity_limit: "This course is at or reaching its capacity limit. ",
  error_capacity_limit_2: " remaining seats ",
  online_video: "Online Video",
  livestream: "Livestream",
  classroom_lecture_only: "Classroom lecture only",
  classroom_lecture_and_hands_on: "Classroom lecture and hands-on",

  cart_provided_by: "Provided by",
  cart_sub_total: "Sub Total:",
  cart_coupon_discount: "Coupon Discount:",
  cart_secure_checkout: "Secure Checkout",
  cart_applicable: "Applicable to",
  cart_applicable_you: " you only ",
  cart_applicable_you_staff: " you and staff ",
  cart_coupon: "Coupon",
  cart_apply_coupon: "Apply Coupon",
  cart_enter_coupon: "Enter Coupon...",
  cart_no_credit_card: "You have no credit card on file, please add one.",
  cart_select_cc: "Select Payment Method",
  cart_update_cc: "Update Payment Method",
  cart_order_summary: "1. Order Summary ",
  cart_payment_info: "3. Payment Information",
  cart_payment_option: "4. Payment Options",
  cart_payment_option_on: "on",
  cart_payment_option_pay_now: "pay now",

  cart_payment_option_pay_multiple: "Payment Instalments",
  cart_payment_option_disclaimer:
    "(Instalments will be automatically charged to the payment method on file on the dates listed above." +
    " Failed payment attempts may result in the termination of your registration without possibility of a refund. " +
    "You are responsible to have a valid payment method on file to assure payments are processed successfully.)",

  cart_terms: "Terms",
  cart_terms_desc:
    "By placing an order, you are agreeing to our General Terms of Use and Privacy Policy.",
  cart_billing: "Billing",
  cart_billing_desc:
    "When purchasing courses from multiple course providers, ZubU will split the charge by provider.  You will receive one receipt per course provider in your order.",
  cart_exchange_rates: "Exchange Rates",
  cart_exchange_rates_you_are: "You are viewing prices in",
  cart_exchange_rates_you_are_desc:
    ". Prices set by providers residing outside your country are displayed in your currency using an average exchange rate. Prices will be charged in the currency of the course provider's country. Conversion rates are determined by your credit card supplier at the time of purchase. Review your credit card statement for exact pricing after the purchase.",

  cart_place_your_order: "Place Your Order",
  cart_processing: "Processing",

  cart_confirm_option: "Confirm Registration Option",
  cart_add_to: "Add to Cart ",

  cart_select_options_staff: "Select options and staff",
  cart_already_in_cart: "Oops.. already in cart",
  cart_already_in_cart_text: " is already in your cart for ",
  cart_already_in_cart_text_try_again:
    ".  Please update your selection or cart and try again.",
  cart_thank_you: "Thank you for shopping",
  cart_added_to_cart: " was added to your cart.",
  cart_payment_option: "payment options at checkout",

  // ------------------------------

  cart_title: "Shopping Cart",
  cart_blurb: "Your shopping cart is empty",
  cart_checkout_now: "Checkout Now",

  // ------------------------------

  pricing_option_credits: "Credits",
  pricing_option_lecture: "Lecture",
  pricing_option_handson: "Hands-on",
  pricing_option_lecture: "Lecture",
  pricing_option_handson: "Hands-on",
  pricing_option_sold_out: "Sold out",
  pricing_option_for: "for",

  pricing_option_pay: "Pay",
  pricing_option_today: "today",
  pricing_option_monthly: "monthly payments",

  // ------------------------------

  course_details_register_now: "Add to cart",
  course_details_message: "Message",
  course_details_message_blurb:
    "Have a question? Message the course provider directly.",
  course_details_certificate_completion: "Certificate of Completion",
  course_certificate: "Certificate",

  course_student_ratings: "Student Ratings",
  course_student_reviews: "Student Reviews",
  course_more_reviews: "More reviews",

  g_out_of: "out of",

  // ------------------------------

  attendance_search_not_found: "No results found matching your criteria",
  attendance_not_checked_in: "Not checked in",
  attendance_checked_in: "Checked in",
  attendance_check_in: "check in",
  attendance_list_empty:
    "Your attendance list is empty. No registered students for the course",
  attendance: "Attendance",
  attendance_start_date: "Start",
  attendance_end_date: "End",
  attendance_license: "License",
  attendance_order: "Order",
  attendance_search_placeholder: "Name, license, order number, etc.",
  attendance_passcode_saved: "Passcode saved successfully",
  attendance_unavailable:
    "Uh-oh, the attendance list you are requesting is not currently available. Please try again later or contact the course provider for more information.",

  attendance_enter_pass_name: "Enter your name and passcode",
  attendance_reg_by: "Registered by",

  attendance_swipe: "Swipe left on student line",

  // ------------------------------

  course_search_not_found: "No courses found matching your criteria",
  course_search_not_found2:
    "Didn't find any results? Our providers are working hard to bring you new courses daily, check back soon.",

  // ------------------------------

  pick_signup: "App Signup",
  pick_first_registration: "First Course Registration",
  pick_first_rate_review: "First Course Rating",
  pick_male: "Male",
  pick_female: "Female",
  pick_other: "Other",
  pick_yes: "Yes",
  pick_no: "No",
  pick_english: "English",
  pick_french: "French",

  pick_paid: "Paid",
  pick_free: "Free",

  pick_livestream: "Livestream",
  pick_classroom: "Classroom",
  pick_online_video: "Online Video",
  pick_lecture_only: "Lecture Only",
  pick_lecture_and_hands_on: "Lecture and Hands-on",
  pricing_option_for: "for",
  pick_hygienist: "Hygienist",
  pick_specialist: "Specialist",
  pick_others: "Others",
  pick_dentist: "Dentist",



  // ------------------------------

  store_open: "Open on ZubU website?",
  store_powered_by_1: "Course registrations with",
  store_powered_by_2: "are powered by",

  store_to_register_action: "To register for this course you will need to:",
  store_to_register_1: "Sign in on the ZubU website",
  store_to_register_2: "Click the add to cart button",
  store_to_register_3: "Proceed with checkout steps",

  // ------------------------------

  sign_up_user_name_blurb:
    "Your user name protects your identity. It can be seen by other members of the ZubU community in the chat or other public areas.",

  error_occurred: "An error occurred",
  error_default_message: "Unknown error has occured.  Please try again later.",
  error_default_fallback:
    "We are working on gettting this fixed.  Try again later.",
  error_permission_denied:
    "Access denied.  Please try again later or contact your ZubU administrator.",
  error_email_exists:
    "An account on the ZubU platform is associated to this email address.  Please enter a different email address.",
  error_weak_password: "Password should be at least 6 characters",
  error_email_not_found: "Invalid email and / or password.",
  error_invalid_email_password: "Invalid email and / or password.",
  error_login_expired: "Login expired",

  error_account_unverified:
    "Your account is not verified.  Check your inbox for an email titled ZubU account verification.",

  error_web_view_load:
    "We hit a snag while navigating.  Our team is aware of this issue and working on a fix.  Please try again.",

  // ------------------------------

  validate_email_required: "Email required",
  validate_is_required: "is required",
  validate_min_length_two: "Minimum length of two characters",
  validate_min_length_six: "Minimum length of six characters",
  validate_min_length_ten: "Minimum length of ten characters",
  validate_please_enter: "Please enter a valid",

  validate_phone_required: "Phone number is required",
  validate_min_length_phone: "Please enter a valid phone number",

  // ------------------------------

  tips_expense: "Track your expenses",
  tips_expense_desc:
    "Track your expenses on the go. Upload your receipts and export summaries in PDF or Excel. Your accountant will love you.",

  tips_start_tracking: "Start Tracking",
  tips_start_tracking_desc:
    "Did you know that you could manually enter course information for courses that were not registered on the app?",

  tips_chat_share: "Chat and Share",
  tips_chat_share_desc:
    "Add colleagues to your friends list and start sharing interesting courses immediately.",

  tips_ce_summary: "CE Summary",
  tips_ce_summary_desc:
    "With ZubU you can export your CE summary and certificates directly from the app.  Simply click the download icon and select the date range.",

  tips_register_team: "Register your team",
  tips_register_team_desc:
    "Team building courses are great.  Add your staff and register the entire team for a quick and easy group checkout.",

  tips_search: "Search the marketplace",
  tips_search_desc:
    "Find dental CE courses by a multitude of providers near and far.  Search by location, language, category and more.",

  // ------------------------------

  // moment
  M_DATE_FORMAT_TINY: "MMM Do",
  M_DATE_FORMAT: "MM-DD-YYYY",
  M_TIME_FORMAT: "h:mm a",
  M_LONG_DATE_FORMAT: "dddd MMMM Do YYYY",
  M_SHORT_DATE_FORMAT: "MMM Do YYYY",
  M_CHAT_FORMAT: "MMM D YYYY",

  // fns
  FNS_DATE_FORMAT: "MM-dd-yyyy",
  FNS_LONG_DATE_FORMAT: "iiii MMMM do yyyy",
  FNS_TIME_FORMAT: "h:mm aa",

  // ------------------------------
};

export default EN_TRANSLATION;
